/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: mapChartMixin.js
Description: This mixin contains the common functionality related to the charts used in maps side menu.
*/
import Highcharts from "highcharts";
import { mapGetters } from "vuex";
import {
  addResizeListener,
  removeResizeListener
} from "element-ui/src/utils/resize-event";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
const mapChartMixin = {
  mixins: [datesHandlerMixin],
  computed: {
    ...mapGetters("googleMaps", {
      getCurrentCulture: "getCurrentCulture",
      pondId: "getSelectedPondId",
      getSelectedPond: "getSelectedPond"
    }),
    ...mapGetters("user", {
      getUserTimeZone: "getUserTimeZone",
      calculatedTimezone: "getUserTimeZoneOffset"
    }),
    getHarvestId() {
      return (
        this.getCurrentCulture || {
          harvest_id: 0
        }
      ).harvest_id;
    },
    availableInterval() {
      return {
        start: this.dhm__dateUtilsLib.parse(
          this.getCurrentCulture.start_date,
          this.dhm__dateUtilsLib.isoFormatString,
          new Date()
        ),
        end: this.dhm__dateUtilsLib.parse(
          this.getCurrentCulture.end_date,
          this.dhm__dateUtilsLib.isoFormatString,
          new Date()
        )
      };
    },
    getCultureDateRange() {
      return [
        this.getCurrentCulture.start_date,
        this.getCurrentCulture.end_date
      ];
    },
    getDateObjArrOfCultureDateRange() {
      const start_date = this.dhm__dateUtilsLib.utcToZonedTime(
        this.getCultureDateRange[0],
        this.getUserTimeZoneString
      );
      const end_date = this.dhm__dateUtilsLib.utcToZonedTime(
        this.getCultureDateRange[1],
        this.getUserTimeZoneString
      );
      return [start_date, end_date];
    },
    dateFormat() {
      return "yyyy-MM-dd";
    }
  },
  watch: {
    getHarvestId: {
      handler: function(newHarvestId, oldHarvestId) {
        if (newHarvestId === 0) {
          this.initDateRangeWithMomentObj(
            this.defaultDateObjDtRangeForCurrCulture
          );
        } else {
          this.initDateRangeWithMomentObj(this.getDateObjArrOfCultureDateRange);
        }
      },
      immediate: true
    },
    pondId: async function() {
      await this.initComponent();
    },
    getCurrLang: function(newvalue, oldvalue) {
      this.initChartLang();
    }
  },
  data: function() {
    return {
      xAxisTextKey: "Comn_feed",
      yAxisTextKey: "Comn_fcr"
    };
  },
  beforeCreate() {
    if (this != null) {
      const lang = {
        loading: this.$t("Comn_loading_pls_wait"),
        noData: this.$t("Comn_no_data_connection"),
        numericSymbolMagnitude: 1000,
        rangeSelectorZoom: this.$t("Comn_zoom"),
        resetZoom: this.$t("Comn_reset_zoom"),
        resetZoomTitle: this.$t("Comn_reset_zoom_1_1")
      };
      Highcharts.setOptions({ lang });
    }
  },
  methods: {
    padDateRanges(arrDateRanges, intervalGap = 3600000) {
      const startOfDay1 = function(value) {
        return +value - (+value % 86400000);
      };
      if (arrDateRanges.length === 0) return [];
      let arrayDates1 = arrDateRanges.map(x => +x);
      const start = +startOfDay1(arrayDates1[0]);
      const endOfDay = start + 86400000;
      const length1 = arrayDates1.length;
      const lastItem1 = arrayDates1[length1 - 1];
      if (!endOfDay || !lastItem1) return [];
      if (arrayDates1[0] - start > intervalGap) {
        arrayDates1 = [start, ...arrayDates1];
      }
      if (endOfDay - lastItem1 >= intervalGap) {
        arrayDates1 = [...arrayDates1, endOfDay - 60000];
      }
      const newArray = [arrayDates1[0]];
      for (let i = 0; i < arrayDates1.length - 1; i++) {
        let k = 0;
        while (
          arrayDates1[i + 1] - (arrayDates1[i] + k * intervalGap) >=
          intervalGap
        ) {
          newArray.push(arrayDates1[i] + k * intervalGap);
          k++;
        }
        const value = arrayDates1[i + 1] - (arrayDates1[i] + k * intervalGap);
        if (value > 0 && value < intervalGap) {
          newArray.push(arrayDates1[i + 1]);
        }
      }
      return newArray;
    },
    debounce: function(ms, fn) {
      var timer;
      return function() {
        clearTimeout(timer);
        var args = Array.prototype.slice.call(arguments);
        args.unshift(this);
        timer = setTimeout(fn.bind.apply(fn, args), ms);
      };
    },
    registerResizeObserver(componentRef) {
      this.$nextTick(() => {
        addResizeListener(
          this.$refs[componentRef].$el,
          this.debounce(100, () => {
            requestAnimationFrame(secs => {
              if (this.$refs.highcharts) {
                this.$refs.highcharts.chart.reflow();
              }
            });
          })
        );
      });
    },
    unRegisterResizeObserver(componentRef) {
      removeResizeListener(
        this.$refs[componentRef].$el,
        this.debounce(100, () => {
          requestAnimationFrame(secs => {
            if (this.$refs.highcharts) {
              this.$refs.highcharts.chart.reflow();
            }
          });
        })
      );
    },
    disableDateMethod: function(time) {
      const endDate = this.dhm__dateUtilsLib.endOfDay(
        this.dhm__dateUtilsLib.utcToZonedTime(
          new Date(),
          this.getUserTimeZoneString
        )
      );
      const startDate = this.dhm__dateUtilsLib.startOfDay(
        this.dhm__dateUtilsLib.subtract(endDate, {
          days: this.getCurrentCulture.doc
        })
      );
      const currDate = time;
      const paramObj = {
        dateRange: [startDate, endDate],
        date: currDate,
        timeZone: this.getUserTimeZoneString,
        actionsOnDate: [],
        actionsOnDateRangeItem: []
      };
      return !this.dhm__dateUtilsLib.queryInDateRange(paramObj);
    },
    initDateRangeWithMomentObj(dateObjArr) {
      const dates = dateObjArr.map(dateItem => {
        return this.dhm__formatByTZOnUTCISOWithLocale(
          this.dhm__dateUtilsLib.formatTZToISO(
            dateItem,
            this.getUserTimeZoneString
          ),
          this.dateFormat
        );
      });
      this.$set(this.dateRange, 0, dates[0]);
      this.$set(this.dateRange, 1, dates[1]);
      this.handleValueChange(this.dateRange);
    },
    checkDateWithInCultureRange(momentDateObj) {
      return momentDateObj.isBetween(
        ...this.getDateObjArrOfCultureDateRange,
        "[]",
        "minutes"
      );
    },
    initChartStrings() {
      const lang = {
        loading: this.$t("Comn_loading_pls_wait"),
        noData: this.$t("Comn_no_data_connection"),
        numericSymbolMagnitude: 1000,
        rangeSelectorZoom: this.$t("Comn_zoom"),
        resetZoom: this.$t("Comn_reset_zoom"),
        resetZoomTitle: this.$t("Comn_reset_zoom_1_1")
      };
      Highcharts.setOptions({ lang });
    },
    initChartLang() {
      this.initChartStrings();
      this.setAxisText();
      this.initChart();
    },
    setAxisText() {
      this.chartOptions.xAxis.title.text = this.$t(this.xAxisTextKey);
      this.chartOptions.yAxis.title.text = this.$t(this.yAxisTextKey);
    },
    initAxisTextKeys(xAxisTextKey, yAxisTextKey) {
      this.xAxisTextKey = xAxisTextKey;
      this.yAxisTextKey = yAxisTextKey;
    }
  }
};
export default mapChartMixin;
