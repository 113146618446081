/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: Maps.js
Description: This file has the model, functions related to the google maps functionality used in the pondlogs customer site
*/
import mapUtils from "@/utils/mapUtils";
import store from "@/store/index";
/* eslint-disable */
class Maps {
  constructor(divElement) {
    this.autoCompleteService = new google.maps.places.AutocompleteService();
    this.center = new google.maps.LatLng(-0.777259, -91.142578);
    this.currentOverlay = null;
    this.drawingManager = new google.maps.drawing.DrawingManager();
    this.domElements = {
      nameOverlay: null,
      infoWindow: null,
      infoPondHealth: null
    };
    this.drawPondComponent = null;
    this.geoCoder = new google.maps.Geocoder(); // geocoder object
    this.map = new google.maps.Map(divElement);
    this.mapData = new google.maps.Data();
    this.mapEventListenerObj = {
      mousemove: undefined,
      zoom_changed: undefined
    };
    this.mapDateEventListeners = {
      addfeature: undefined,
      click: undefined,
      mouseover: undefined,
      mouseout: undefined
    };
    this.pondDrawnPlace = "";
    this.pondOverInfoWindow = null;
    this.overlays = new Map();
    this.searchedPlaceMarker = null;
    this.searchedPlaceInfoWindow = null;
  }
  generateColorForDo(data) {
    if (!data.do && data.do !== 0) {
      return "";
    }
    const doConfig = data.doConfig || {
      critical_lower_limit: 1.5,
      lower_limit: 2.5
    };
    if (data.do < doConfig.critical_lower_limit) {
      return "#ff0000"; //critical Do
    } else if (
      data.do >= doConfig.critical_lower_limit &&
      data.do < doConfig.lower_limit
    ) {
      return "#ffa500"; //low  Do
    }
    return "";
  }
  // isDoInAlertState(data){

  // }
  setOptions(optionType) {
    let getColor = this.generateColorForDo;
    switch (optionType) {
      case "MAP":
        let options = {
          mapTypeId: google.maps.MapTypeId.HYBRID,
          center: this.center,
          zoom: 16,
          minZoom: 4,
          zoomControl: true,
          disableDefaultUI: true,
          fullscreenControl: true,
          clickableIcons: false,
          fullscreenControlOptions: {
            position: google.maps.ControlPosition.RIGHT_CENTER
          }
        };
        this.map.setOptions(options);
        break;
      case "DATA":
        this.mapData.setStyle(function(feature) {
          var color = "#dcdcdc";
          var strokeColor = "#dcdcdc";

          if (feature.getProperty("status") !== "ACTIVE") {
            color = "#dcdcdc";
          } else if (
            feature.getProperty("pond_guards").length > 0 &&
            !!getColor(feature.getProperty("disolved_oxygen"))
          ) {
            color = getColor(feature.getProperty("disolved_oxygen"));
          }
          return /** @type {!google.maps.Data.StyleOptions} */ ({
            fillColor: color,
            strokeColor: strokeColor,
            fillOpacity: 0.5,
            strokeWeight: 2
          });
        });
        break;
      case "NEW_POLYGON":
        let polygonOptions = {
          draggable: false,
          clickable: true,
          editable: true,
          fillColor: "#dcdcdc",
          fillOpacity: 0.5,
          strokeColor: "#dcdcdc",
          strokeWidth: 40
        };
        this.drawingManager.setOptions({
          polygonOptions,
          drawingControl: false
        });
        break;
    }
  }
  setMapListener() {
    const mouseMoveEventListener = this.map.addListener("mousemove", event => {
      if (this.pondOverInfoWindow != null) {
        this.pondOverInfoWindow.close();
      }
    });
    let markers = new Map(); // javascript map data structure
    let locationOverlayView = new google.maps.OverlayView();
    let latLngBound = new google.maps.LatLngBounds();
    const zoomChangeEventListener = this.map.addListener(
      "zoom_changed",
      event => {
        // hiding the infowindow if any is active
        if (this.pondOverInfoWindow != null) {
          this.pondOverInfoWindow.close();
        }
        let mapZoom = this.map.getZoom();
        if (mapZoom < 11) {
          if (locationOverlayView.getMap()) {
            locationOverlayView.setMap(null);
          }
          let countryPosition = latLngBound.getCenter();
          let countryName = store.getters["user/getUserProfile"].country.name;
          this.initLocationOverlayView(
            locationOverlayView,
            countryPosition,
            countryName
          );
        } else if (mapZoom <= 14 && mapZoom >= 11) {
          this.overlays.forEach((overlay, featureId) => {
            let pondPathFeature = this.mapData.getFeatureById(featureId);
            this.mapData.overrideStyle(pondPathFeature, { visible: false });
            if (overlay.getMap()) {
              this.setMap(overlay, false);
              let center = this.mapData
                .getFeatureById(featureId)
                .getProperty("center");
              if (!latLngBound.contains(center)) {
                latLngBound.extend(center);
              }
            }
          });
          let locationPosition = latLngBound.getCenter();
          let locationName = store.getters["user/getCurrUserLocation"].name;
          if (locationOverlayView.getMap()) {
            locationOverlayView.setMap(null);
          }
          this.initLocationOverlayView(
            locationOverlayView,
            locationPosition,
            locationName
          );
        } else if (mapZoom > 14) {
          if (locationOverlayView.getMap()) {
            locationOverlayView.setMap(null);
          }
          this.overlays.forEach((overlay, featureId) => {
            let pondPathFeature = this.mapData.getFeatureById(featureId);
            this.mapData.revertStyle(pondPathFeature);
            this.setMap(overlay, true);
            let marker = markers.get(featureId);
            if (marker != null) {
              marker.setVisible(false);
              this.setMap(marker, false);
            }
          });
        }
      }
    );
    [
      { name: "zoom_changed", obj: zoomChangeEventListener },
      { name: "mousemove", obj: mouseMoveEventListener }
    ].forEach(lstnrObj => {
      this.mapEventListenerObj[lstnrObj.name] = lstnrObj.obj;
    });
  }
  addAllListeners() {
    this.addMapDataListeners();
    this.setMapListener();
  }
  removeAllListeners() {
    ["addfeature", "click", "mouseover", "mouseout"].forEach(eventName => {
      this.mapDateEventListeners[eventName].remove();
    });
    // ['click','mouseout','']
    ["mousemove", "zoom_changed"].forEach(eventName => {
      this.mapEventListenerObj[eventName].remove();
    });
  }
  initLocationOverlayView(overlay, position, name) {
    var domElement = this.domElements.nameOverlay.cloneNode(true);
    //  Removing the overlay placed on the map
    domElement.id = "overlayview-location";
    domElement.classList.add("location-info-label");
    overlay.onRemove = function() {
      if (domElement.parentElement) {
        domElement.parentElement.removeChild(domElement);
      }
    };
    //  updating position on update the zoom the overlay on the map
    overlay.draw = () => {
      let panes = overlay.getPanes();
      let projection = overlay.getProjection();
      let pixelPosition = projection.fromLatLngToDivPixel(position);
      panes["overlayMouseTarget"].appendChild(domElement);
      domElement.style["left"] = pixelPosition.x + "px";
      domElement.style["top"] = pixelPosition.y + "px";
    };
    // initializing the overlay to the map
    overlay.onAdd = () => {
      let projection = overlay.getProjection();
      var pixelPosition = projection.fromLatLngToDivPixel(position);
      domElement.innerHTML = name;
      // name.length > 8
      //   ? `<span class="marquee"><span>${name}</span></span>`
      //   : `${name}`;
      domElement.style["left"] = pixelPosition.x + "px";
      domElement.style["top"] = pixelPosition.y + "px";
    };
    this.setMap(overlay, true);
  }
  panTo(latLng) {
    this.map.panTo(latLng);
  }
  setDOMElement(key, DOMElement) {
    this.domElements[key] = DOMElement;
  }
  setMapCenter(center) {
    this.map.setCenter(center);
  }
  setMapData() {
    this.mapData.setMap(this.map);
    this.setOptions("DATA");
  }
  resetDrawingControl() {
    this.drawingManager.setDrawingMode(null);
  }
  removeListerners(MVCObject) {
    google.maps.event.clearInstanceListeners(MVCObject);
  }
  setMap(MVCObject, value) {
    if (value) MVCObject.setMap(this.map);
    else MVCObject.setMap(null);
  }
  addMarkerToMap(latLng) {
    let marker = new google.maps.Marker({
      position: latLng
    });
    return marker;
  }
  setControls(position, DOMElement) {
    this.map.controls[google.maps.ControlPosition[position]].push(DOMElement);
  }
  removeControls(position) {
    if (this.map.controls[google.maps.ControlPosition[position]].length > 0) {
      this.map.controls[google.maps.ControlPosition[position]].pop();
    }
  }
  setDrawingControl() {
    this.removeListerners(this.mapData);
    this.removeElementsAddedBySearch();
    this.setOptions("NEW_POLYGON");
    this.drawingManager.setDrawingMode("polygon");
    this.setMap(this.drawingManager, true);
    this.drawingManager.addListener("polygoncomplete", async polygon => {
      if (polygon.getPath().length <= 2) {
        store.commit("googleMaps/SET_POND_SIZE_ALERT", true);
        polygon.setMap(null);
      } else {
        this.resetDrawingControl();
        this.currentOverlay = polygon;
        // current pond is updated
        this.updateOverlayToStore(polygon, "DRAW_POND_COMPLETE");

        let center = mapUtils.getPolyCenter(polygon.getPath());
        this.map.panTo(center);
        await this.geoCoderLatLng(center);
        this.addPolygonListeners(
          this.currentOverlay,
          this.updateOverlayToStore,
          "DRAW_POND_COMPLETE"
        );
      }
    });
  }
  removeElementsAddedBySearch() {
    this.searchedPlaceInfoWindow &&
      this.setMap(this.searchedPlaceInfoWindow, false);
    this.searchedPlaceMarker && this.setMap(this.searchedPlaceMarker, false);
  }
  async geoCoderLatLng(latlng) {
    try {
      await store.dispatch(
        "googleMaps/setMapPondDrawnPlaceLatLngStr",
        [latlng.lat(), latlng.lng()].toString()
      );
    } catch (err) {
      console.error(err);
    }
  }
  initAutoComplete() {
    var autocomplete = new google.maps.places.Autocomplete(
      this.domElements.searchPlaceControl
    );
    autocomplete.bindTo("bounds", this.map);
    autocomplete.setFields(["address_components", "geometry", "icon", "name"]);
    this.searchedPlaceInfoWindow = new google.maps.InfoWindow();
    var infowindowContent = this.domElements.infoWindow;
    this.searchedPlaceInfoWindow.setContent(infowindowContent);
    this.searchedPlaceMarker = new google.maps.Marker({
      map: this.map,
      anchorPoint: new google.maps.Point(0, -29)
    });
    autocomplete.addListener("place_changed", () => {
      this.searchedPlaceInfoWindow.close();
      this.searchedPlaceMarker.setVisible(false);
      var place = autocomplete.getPlace();
      if (!place.geometry) {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        window.alert("No details available for input: '" + place.name + "'");
        return;
      }
      // If the place has a geometry, then present it on a map.
      if (place.geometry.viewport) {
        this.map.fitBounds(place.geometry.viewport);
      } else {
        this.map.setCenter(place.geometry.location);
        this.map.setZoom(17); // Why 17? Because it looks good.
      }
      this.searchedPlaceMarker.setPosition(place.geometry.location);
      this.searchedPlaceMarker.setVisible(true);
      var address = "";
      if (place.address_components) {
        address = [
          (place.address_components[0] &&
            place.address_components[0].short_name) ||
            "",
          (place.address_components[1] &&
            place.address_components[1].short_name) ||
            "",
          (place.address_components[2] &&
            place.address_components[2].short_name) ||
            ""
        ].join(" ");
      }
      const iconElement = infowindowContent.querySelector("#place-icon");
      const placeElement = infowindowContent.querySelector("#place-name");
      const addressElement = infowindowContent.querySelector("#place-address");
      const closeElement = infowindowContent.querySelector("#close");
      iconElement.src = place.icon;
      placeElement.textContent = place.name;
      store.dispatch("googleMaps/searchedPlaceName", place.name);
      addressElement.textContent = address;
      closeElement.onclick = () => {
        this.searchedPlaceInfoWindow.close();
        this.searchedPlaceMarker.setVisible(false);
      };
      this.searchedPlaceInfoWindow.open(this.map, this.searchedPlaceMarker);
    });
  }
  async updateOverlayToStore(overlay, mode = "") {
    let coordinates = [];
    let area = mapUtils.getArea(overlay);
    overlay
      .getPath()
      .getArray()
      .forEach(latLng => {
        coordinates.push([latLng.lng(), latLng.lat()]);
      });
    if (mode === "EDITING") {
      await store.dispatch("googleMaps/updateToCurrentHoverPondInMap", {
        coordinates,
        area
      });
    } else if (mode === "DRAW_POND_COMPLETE") {
      await store.dispatch("googleMaps/setMapMode", "DRAW_POND_COMPLETE");
      await store.dispatch("googleMaps/updateCurrDrawingPond", {
        coordinates,
        area
      });
    }
  }
  addPolygonListeners(overlay, updateOverlayToStore, mode = "") {
    ["insert_at", "remove_at", "set_at"].forEach(event => {
      overlay.getPath().addListener(event, async function(index) {
        if (mode === "DRAW_POND_COMPLETE") {
          await updateOverlayToStore(overlay, "DRAW_POND_COMPLETE");
        } else {
          await updateOverlayToStore(overlay, "EDITING");
        }
      });
    });
  }
  addMapDataListeners() {
    const addFeatureListener = this.mapData.addListener(
      "addfeature",
      ({ feature }) => {
        // adding new name overlay to the overlays map object
        this.overlays.set(feature.getId(), new google.maps.OverlayView());
        this.overlayPondInfoElements(feature);
      }
    );
    const clickListener = this.mapData.addListener(
      "click",
      async ({ feature }) => {
        this.removeAllListeners();
        this.currentSelectedOverlay = feature;
        const featureId = feature.getId();
        const element = document.getElementById(
          `pond-details-name-${featureId}`
        );
        const elementInnerHtml = element.innerHTML;
        const loadingStr = `<div>
         <i class="el-icon-loading"></i>
        </div>`;

        element.innerHTML = loadingStr;
        try {
          await store.dispatch("googleMaps/currentClickedPondInMap", featureId);
          await store.dispatch("googleMaps/fetchAPondDetails", featureId);
          await store.dispatch("googleMaps/setMapMode", "SHOW_DETAILS_OF_POND");
        } finally {
          element.innerHTML = elementInnerHtml;
          this.addAllListeners();
          this.map.panToBounds(feature.getProperty("latLgnBounds"), {
            top: 300,
            right: 900,
            left: 100,
            bottom: 300
          });
        }
      }
    );
    let element;
    let elementInnerHtml;
    let increment = 0;
    const mouseoverListener = this.mapData.addListener(
      "mouseover",
      async featureObj => {
        // removing listerners of the map to prevent hovering event on another element
        // this.removeListerners(this.mapData);
        // this.removeListerners(this.map);
        this.removeAllListeners();
        this.currentSelectedOverlay = featureObj.feature;
        let featureid = featureObj.feature.getId();
        element = document.getElementById(`pond-details-name-${featureid}`);
        elementInnerHtml = element && element.innerHTML;
        if (element) {
          const loadingStr = `<div>
          <i class="el-icon-loading"></i>
        </div>`;
          element.innerHTML = loadingStr;
          await store.dispatch("googleMaps/currentHoverPondInMap", featureid);
          element.innerHTML = elementInnerHtml;
        }
        this.addAllListeners();
        if (this.pondOverInfoWindow != null) {
          this.pondOverInfoWindow.close();
        }
        this.pondOverInfoWindow = this.openInfowindow(featureObj.feature);
        this.pondOverInfoWindow.open(this.map);
      }
    );
    const mouseoutListener = this.mapData.addListener(
      "mouseout",
      featureObj => {
        // Closing in infowindow open in the mouseover event

        if (element) {
          element.innerHTML = elementInnerHtml;
        }
        if (this.pondOverInfoWindow != null) {
          this.pondOverInfoWindow.close();
        }
      }
    );
    [
      { name: "addfeature", obj: addFeatureListener },
      { name: "click", obj: clickListener },
      { name: "mouseover", obj: mouseoverListener },
      { name: "mouseout", obj: mouseoutListener }
    ].forEach(eventObj => {
      this.mapDateEventListeners[eventObj.name] = eventObj.obj;
    });
  }
  openInfowindow(feature) {
    /***
     * 1.Get the htmlString of the infowindow and converted to the DOMNode
     * 2.Created Infowindow object and Added the DOMNode to the infowindow
     * 3.Added to button click listener to listen to the edit event
     */
    let htmlNode = document
      .getElementById("pond-details-info-container")
      .cloneNode(true);

    // let infoElement = console.log(typeof infoElement);
    let infowindow = new google.maps.InfoWindow({
      content: htmlNode,
      position: feature.getProperty("center"),
      pixelOffset: new google.maps.Size(10, -20, "px", "px")
    });
    // console.log(htmlNode.querySelector(".btn-pond-edit"));
    htmlNode
      .querySelector(".btn-pond-edit")
      .addEventListener("click", event => {
        const canUserEdit = store.getters["googleMaps/getCanUserEdit"];
        if (!canUserEdit) {
          store.commit("googleMaps/SET_ACCESS_DENIED", true);
          return;
        } else if (feature.getProperty("status") === "ACTIVE") {
          store.dispatch("googleMaps/setMapMode", "EDITING");
          this.editPond(feature.getId());
        } else {
          store.dispatch("googleMaps/setMapMode", "ACTIVATING");
          this.activatePond(feature.getId());
        }
      });

    // htmlNode
    // .querySelector(".btn-pond-edit")
    // .addEventListener("click", (event) => {
    //   const canUserEdit = store.getters["googleMaps/getCanUserEdit"];
    //   if (!canUserEdit) {
    //     store.commit("googleMaps/SET_ACCESS_DENIED", true);
    //     return;
    //   }

    // });
    return infowindow;
  }

  activatePond(featureId) {
    // initializing the map mode to edit_pond

    // removing mapdata listeners
    this.removeAllListeners();
    // closing infowindow which is opened
    if (this.pondOverInfoWindow != null) {
      this.pondOverInfoWindow.close();
    }
    // preparing the current pond to update
    // let featureId = feature.getId();
    let feature = this.mapData.getFeatureById(featureId);
    this.mapData.overrideStyle(feature, {
      visible: false
    });
    let polyCoor = feature.getGeometry().getArray()[0];

    let polygon = new google.maps.Polygon();
    polygon.setPath(polyCoor.getArray());
    polygon.setEditable(false);
    polygon.setVisible(true);
    // updating the curret pond using overlay
    // this.updateOverlayToStore(polygon, "EDITING");
    // adding polygon listeners
    this.addPolygonListeners(polygon, this.updateOverlayToStore);
    polygon.set("pond_id", featureId);
    this.setMap(polygon, true);
    this.currentOverlay = polygon;
    // hiding the name overlay of the pond
    let overlay = this.overlays.get(featureId);
    if (overlay) {
      overlay.setMap(null);
    }
    this.map.panTo(feature.getProperty("center"));
  }

  editPond(featureId) {
    // initializing the map mode to edit_pond

    // removing mapdata listeners
    this.removeAllListeners();
    // closing infowindow which is opened
    if (this.pondOverInfoWindow != null) {
      this.pondOverInfoWindow.close();
    }
    // preparing the current pond to update
    // let featureId = feature.getId();
    let feature = this.mapData.getFeatureById(featureId);
    this.mapData.overrideStyle(feature, {
      visible: false
    });
    let polyCoor = feature.getGeometry().getArray()[0];

    let polygon = new google.maps.Polygon();
    polygon.setPath(polyCoor.getArray());
    polygon.setEditable(true);
    polygon.setVisible(true);
    // updating the curret pond using overlay
    // this.updateOverlayToStore(polygon, "EDITING");
    // adding polygon listeners
    this.addPolygonListeners(polygon, this.updateOverlayToStore);
    polygon.set("pond_id", featureId);
    this.setMap(polygon, true);
    this.currentOverlay = polygon;
    // hiding the name overlay of the pond
    let overlay = this.overlays.get(featureId);
    if (overlay) {
      overlay.setMap(null);
    }
    this.map.panTo(feature.getProperty("center"));
  }
  overlayPondInfoElements(feature) {
    let featureid = feature.getId();
    if (this.overlays.get(featureid)) {
      var domElement = this.domElements.nameOverlay.cloneNode(true);
      let position = feature.getProperty("center");
      let pondTitle = feature.getProperty("title");
      let overlay = this.overlays.get(featureid);
      domElement.id = "pond-" + featureid;
      domElement.querySelector("#pond-details-name").id += "-" + featureid;
      domElement.classList.add("info-label");
      let activePond = feature.getProperty("status");
      // let pondDO = feature.getProperty("dissolved_oxygen");
      if (activePond === "INACTIVE") {
        domElement
          .querySelector("#pond-details-name-" + featureid)
          .classList.add("inactive-pond");
      }
      //  Removing the overlay placed on the map
      overlay.onRemove = function() {
        if (domElement.parentElement) {
          domElement.parentElement.removeChild(domElement);
        }
      };
      //  updating position on update the zoom the overlay on the map
      overlay.draw = () => {
        let panes = overlay.getPanes();
        let projection = overlay.getProjection();
        let pixelPosition = projection.fromLatLngToDivPixel(position);
        panes["overlayMouseTarget"].appendChild(domElement);
        domElement.style["left"] = pixelPosition.x + "px";
        domElement.style["top"] = pixelPosition.y + "px";
      };
      // initializing the overlay to the map
      overlay.onAdd = () => {
        let projection = overlay.getProjection();
        var pixelPosition = projection.fromLatLngToDivPixel(position);
        domElement.querySelector("#pond-details-name-" + featureid).innerHTML =
          pondTitle.length > 8
            ? `<span class="marquee"><span>${pondTitle}</span></span>`
            : `${pondTitle}`;
        domElement.style["left"] = pixelPosition.x + "px";
        domElement.style["top"] = pixelPosition.y + "px";
      };
      // set the overlayview when the zoom is greater than 14
      if (this.map.getZoom() > 14) {
        this.setMap(overlay, true);
      }
    }
  }
}

export default Maps;
