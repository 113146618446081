/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: errorAlertsUtils.js
Description: Contains stateless helper functions used in showing device alerts
*/
import NO_COMMUNICATION from "@/assets/alerts/connectivity-alert-v2.svg";
import NO_SCHEDULES from "@/assets/alerts/no-schedule-alert-v2.svg";
import FEED_NOT_BEING_DISPENSED from "@/assets/alerts/feed-alert-v2.svg";
import MOTOR_RELAY_ISSUE from "@/assets/alerts/motor-retail-failure-v2.svg";
import SCHEDULE_NOT_UPDATED from "@/assets/alerts/schedules-not-updated-v2.svg";
import LOW_DO from "@/assets/alerts/low-do-alert-v2.svg";
import CRITICAL_DO from "@/assets/alerts/critical-do-alert-v2.svg";
import LOW_BATTERY from "@/assets/navigation/nav-battery.svg";
import CALIBRATION_ERROR from "@/assets/alerts/calibration-error.svg";
import HIGH_TEMPERATURE from "@/assets/alerts/high-temperature-type-1.svg";
import LOW_TEMPERATURE from "@/assets/alerts/low-temperature-type-2.svg";
import DO_SENSOR_DISCONNECTED from "@/assets/alerts/SensorDisconnected.svg";
import SOLAR_DISCONNECTED from "@/assets/alerts/SolarDisconnected.svg";
import DO_SENSOR_INVALID_DATA from "@/assets/alerts/SensorInvalidData.svg";
import DO_SENSOR_NOT_RESPONDING from "@/assets/alerts/SensorNotResponding.svg";
export default {
  renderAlertName(data) {
    switch (data) {
      case "NO_COMMUNICATION":
        return `NO_COMMUNICATION`;
      case "NO_SCHEDULES":
        return `PM_no_sch_today`;
      case "SCHEDULE_NOT_UPDATED":
        return "PM_sch_not_updated";
      case "FEED_NOT_BEING_DISPENSED":
        return `FEED_NOT_BEING_DISPENSED`;
      case "LOW_BATTERY":
        return `ALERTS_LOW_BATTERY`;
      case "NO_FEED_OR_FEED_STUCK":
        return `PM_NO_FEED_ALERT`;
      case "MOTOR_RELAY_ISSUE":
        return `PM_motor_failure_alert`;
      case "LOW_DO":
        return "Comn_low_do";
      case "CRITICAL_DO":
        return "Comn_critical_do";
      case "CALIBRATION_ERROR":
        return "Comn_calibration_error";
      case "NO_DO_SENSOR_COMMUNICATION":
        return "no_do_sensor_communication";
      case "LOW_TEMPERATURE":
        return "low_temperature";
      case "HIGH_TEMPERATURE":
        return "high_temperature";
      case "DO_SENSOR_DISCONNECTED":
        return "do_sensor_disconnected";
      case "DO_SENSOR_NO_RESPONSE":
        return "do_sensor_no_response";
      case "DO_INVALID_READING":
        return "do_invalid_reading";
      case "SOLAR_DISCONNECTED":
        return "solar_disconnected";
      default:
        return data;
    }
  },
  renderDeviceAlertIcon(data) {
    switch (data) {
      case "NO_COMMUNICATION":
        return NO_COMMUNICATION;
      case "NO_SCHEDULES":
        return NO_SCHEDULES;
      case "FEED_NOT_BEING_DISPENSED":
        return FEED_NOT_BEING_DISPENSED;
      case "NO_FEED_OR_FEED_STUCK":
        return FEED_NOT_BEING_DISPENSED;
      case "MOTOR_RELAY_ISSUE":
        return MOTOR_RELAY_ISSUE;
      case "SCHEDULE_NOT_UPDATED":
        return SCHEDULE_NOT_UPDATED;
      case "LOW_DO":
        return LOW_DO;
      case "CRITICAL_DO":
        return CRITICAL_DO;
      case "LOW_BATTERY":
        return LOW_BATTERY;
      case "CALIBRATION_ERROR":
        return CALIBRATION_ERROR;
      case "NO_DO_SENSOR_COMMUNICATION":
        return NO_COMMUNICATION;
      case "LOW_TEMPERATURE":
        return LOW_TEMPERATURE;
      case "HIGH_TEMPERATURE":
        return HIGH_TEMPERATURE;
      case "DO_SENSOR_DISCONNECTED":
        return DO_SENSOR_DISCONNECTED;
      case "DO_SENSOR_NO_RESPONSE":
        return DO_SENSOR_NOT_RESPONDING;
      case "DO_INVALID_READING":
        return DO_SENSOR_INVALID_DATA;
      case "SOLAR_DISCONNECTED":
        return SOLAR_DISCONNECTED;
      default:
        return NO_COMMUNICATION;
    }
  }
};
