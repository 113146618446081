<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: mapPondSchedules.vue
Description: This file contains UI components that are shown in schedules tab shown in pond details (right panel) shown maps page. It is a container component and holds tabs and data table
-->
<template>
  <div class="map-pond-schedule-data">
    <el-tabs v-model="selectedTab" :key="getPondTitle">
      <el-tab-pane :name="getPondId" :label="getPondTitle"> </el-tab-pane>
      <el-tab-pane
        v-for="i in getPondMothers"
        :key="i._id"
        :name="`${i._id}`"
        :label="getPondMotherTitle(i._id)"
      ></el-tab-pane>
    </el-tabs>
    <el-table
      size="mini"
      :data="tableTimeSlotsData"
      class="table-size"
      style="width: 100%"
    >
      <el-table-column
        v-for="column in tableColumns"
        :key="column.prop"
        :prop="column.prop"
        :label="column.label"
      >
        <template slot-scope="scope">
          <template v-if="column.prop === 'feed_gap'">
            {{ ftm__digitPrecision(scope.row[column.prop], 2) }}
          </template>
          <template
            v-else-if="['TF', 'DF', 'OCF', 'FG'].indexOf(column.label) > -1"
          >
            {{ ftm__digitPrecision(scope.row[column.prop], 2) }}
          </template>
          <template v-else>{{ scope.row[column.prop] }}</template>
        </template>
      </el-table-column>
    </el-table>
    <div class="foot-note">
      <strong>{{ $t("Comn_note") }}</strong>
      :
      <span v-for="(value, key) in tableColumns" :key="key + 'abbr'">
        <strong>{{ key }}</strong>
        :
        <span>{{ value.abbr }}</span>
        {{ key === "FG" ? "" : "," }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import filtersMixin from "@/mixins/filtersMixin";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";

export default {
  mixins: [filtersMixin, datesHandlerMixin, userPreferenceMixin],
  data: function() {
    return {
      tableTimeSlotsData: [],
      selectedTabName: "",
      pondSchedulesLength: 0
    };
  },
  watch: {
    pondSchedules: function(newValue, oldValue) {
      this.selectedTabName = this.getPondId;
      this.tableTimeSlotsData = this.pondSchedules;
    }
  },
  mounted: function() {
    this.upm__setDataKeys("code", "title");
  },
  computed: {
    ...mapGetters("googleMaps", {
      getExistSchedDtSecsToPmIdToPmSchedDtls:
        "getExistSchedDtSecsToPmIdToPmSchedDtls",
      getExistSchedDtSecsToPondIdToPondSchedDtls:
        "getExistSchedDtSecsToPondIdToPondSchedDtls",
      getSelectedPond: "getSelectedPond",
      getPmIdPmDetailsOfSelectedPondId: "getPmIdPmDetailsOfSelectedPondId"
    }),
    ...mapGetters("user", {
      getPreferredUnits: "getPreferredUnits"
    }),
    tableColumns: function() {
      return {
        From: {
          label: this.$t("Comn_from"),
          prop: "s_time",
          abbr: this.$t("Comn_from_time")
        },
        To: {
          label: this.$t("Comn_to"),
          prop: "e_time",
          abbr: this.$t("Comn_to_time")
        },
        TF: {
          label: "TF",
          prop: "feed",
          abbr: this.$t("Comn_total_feed")
        },
        DF: {
          label: "DF",
          prop: "dispensed_feed",
          abbr: this.$t("PM_day_disp_feed")
        },
        OCF: {
          label: "OCF",
          prop: "ocf",
          abbr: this.$t("Comn_ocf_name")
        },
        FG: {
          label: "FG",
          prop: "feed_gap",
          abbr: this.$t("Comn_feed_gap")
        }
      };
    },
    currentDay: function() {
      return this.dhm__dateUtilsLib
        .startOfDay(this.dhm__getTodayInUserTZ)
        .getTime();
    },
    getPondMothers() {
      return this.getSelectedPond.pond_mothers;
    },
    getPondMotherTitle() {
      return (pondId) => {
        return this.upm__getValue(
          this.getPmIdPmDetailsOfSelectedPondId[pondId]
        );
      };
    },
    getPondTitle() {
      return this.getSelectedPond.title;
    },
    getPondId() {
      return this.getSelectedPond._id;
    },
    selectedTab: {
      get() {
        return this.selectedTabName === ""
          ? this.getSelectedPond._id
          : this.selectedTabName;
      },
      set(tabName) {
        this.selectedTabName = tabName;
        const key =
          this.currentDay + "_" + this.getSelectedPond._id + "_" + tabName;
        if (tabName === this.getPondId) {
          this.tableTimeSlotsData = this.pondSchedules;
        } else {
          this.tableTimeSlotsData = (
            this.getExistSchedDtSecsToPmIdToPmSchedDtls[key] || {
              time_slots: []
            }
          ).time_slots;
        }
      }
    },
    pondSchedules: function() {
      const key = this.currentDay + "_" + this.getPondId;
      const pondTSKeys = Object.keys(
        this.getExistSchedDtSecsToPondIdToPondSchedDtls
      );
      if (pondTSKeys.length > 0) {
        const mapFromTimeToIndexPos = {};
        const arrPondTS = [];
        (
          this.getExistSchedDtSecsToPondIdToPondSchedDtls[key] || {
            time_slots: []
          }
        ).time_slots.forEach((ts) => {
          const maxOCF = Math.max(...ts.pond_mothers.map((x) => x.ocf));
          const maxFG = Math.max(...ts.pond_mothers.map((x) => x.feed_gap));
          const totalFeed = ts.pond_mothers
            .map((x) => x.feed)
            .reduce((prev, curr) => prev + curr);
          const totalDispFeed = ts.pond_mothers
            .map((x) => x.dispensed_feed)
            .reduce((prev, curr) => prev + curr);
          if (mapFromTimeToIndexPos[ts.s_time] === undefined) {
            arrPondTS.push({
              e_time: ts.e_time,
              s_time: ts.s_time,
              ocf: maxOCF,
              feed_gap: maxFG,
              feed: totalFeed,
              dispensed_feed: totalDispFeed
            });
          } else {
            const indexPondTS = mapFromTimeToIndexPos[ts.s_time];
            const tempPondTS = arrPondTS[indexPondTS];
            tempPondTS.feed += totalFeed;
            tempPondTS.dispensed_feed += totalDispFeed;
            tempPondTS.feed_gap = Math.max(tempPondTS.feed_gap, maxFG);
            tempPondTS.ocf = Math.max(tempPondTS.ocf, maxOCF);
            const tempPondTsET = this.$commonUtils.timeStrHHmmVal(
              tempPondTS.e_time
            );
            const pondTsEt = this.$commonUtils.timeStrHHmmVal(ts.e_time);
            if (tempPondTsET < pondTsEt) {
              tempPondTS.e_time = ts.e_time;
            }
          }
          mapFromTimeToIndexPos[ts.s_time] = arrPondTS.length - 1;
        });
        return arrPondTS;
      }
      return [];
    }
  }
};
</script>

<style lang="scss">
.map-pond-schedule-data {
  margin-left: 10px;
  .el-table__body-wrapper {
    @include responsiveProperty(height, 270px, 400px, 590px);
    overflow: auto;
  }
  .foot-note {
    padding: 10px;
    text-align: center;
    @include responsiveProperty(
      font-size,
      $app_font_size_small,
      $app_font_size_1,
      $app_font_size_2
    );
  }
  .el-table--mini,
  .el-table--small,
  .el-table__expand-icon {
    @include responsiveProperty(
      font-size,
      $app_font_size_1,
      $app_font_size_2,
      $app_font_size_3
    );
  }
  .el-table td .cell {
    color: black;
  }
  .el-table th > .cell {
    line-height: 120%;
    @include responsiveProperty(
      font-size,
      $app_font_size_small,
      $app_font_size_1,
      $app_font_size_2
    );
    color: $button-color;
    text-align: left;
    justify-items: flex-start;
    align-items: center;
    background-color: white;
  }
}
</style>
