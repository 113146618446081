<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: activatePond.vue
Description: This file contains a form with details of the pond click on maps page
-->
<template>
  <div id="edit-pond">
    <div id="edit-pond-element">
      <el-card class="action-display-panel">
        <template slot="header">
          <h3>{{ $t("Map_activate_pond") }}</h3>
        </template>
        <er-form
          :inline="true"
          :model="formPond"
          :ref="formName"
          size="small"
          :hide-required-asterisk="true"
          class="form-container"
          label-position="left"
        >
          <el-form-item
            :label="$t('Comn_pond_name')"
            prop="title"
            :error="ekmm__castErrorKeyToLang(ehm__errMessagesObject['title'])"
          >
            <el-input
              v-model="formPond.title"
              :placeholder="$t('Comn_pond_name')"
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="ftm__capitalize($tc('Comn_location.case.lower', 1))"
            prop="location_id"
            :error="ekmm__castErrorKeyToLang(ehm__errMessagesObject['name'])"
          >
            <er-select
              value-key="name"
              allow-create
              filterable
              :placeholder="ftm__capitalize($tc('Comn_location.case.lower', 1))"
              popper-class="location-select__popper"
              default-first-option
              :value="currPondLocation"
              @input="handleChangeInLocation"
              class="select-location"
              :disabled="true"
            >
              <!-- :filter-method="filterLocations" -->
              <el-option
                v-for="location in getUserLocationsObj"
                :key="`${location.name} ${location._id}`"
                :value="location.name.toLowerCase()"
                :label="location.name"
              ></el-option>
            </er-select>
          </el-form-item>
          <!-- {{ $t("Pond_size", { units: $t(pondSizeUnits.lang_key) }) }} -->
          <el-form-item
            :label="$tc('Pond_size')"
            prop="size"
            :error="ekmm__castErrorKeyToLang(ehm__errMessagesObject['size'])"
          >
            <el-input
              type="number"
              :value="parseFloat(Number(formPond.size).toFixed(2))"
              :controls="false"
              :placeholder="$tc('Pond_size')"
              :min="0.1"
              :precision="2"
              :clearable="false"
              @input="handleSizeChange"
            ></el-input>
            <div class="error-msg" v-if="isError">{{$t('Pond_size_error_msg', { maxArea: this.maxArea, minArea: this.minArea })}}</div>
          </el-form-item>

          <el-form-item :label="$t('Comn_units')">
            <er-select :value="formPond.units" @change="handleChangeAreaUnits">
              <el-option
                v-for="(unit, key) in units"
                :key="unit"
                :label="unit"
                :value="key"
              ></el-option>
            </er-select>
          </el-form-item>
          <el-form-item
            :label="$t('Comn_stocking_date')"
            :error="
              ekmm__castErrorKeyToLang(
                ehm__errMessagesObject['cultivation_date']
              )
            "
          >
            <el-col :span="24">
              <el-date-picker
                class="date-picker-stock-date"
                v-model="cultivationendDate"
                type="date"
                value-format="yyyy-MM-dd"
                :format="$commonUtils.DATE_OBJ_DATE_FORMAT_WITH_YEAR"
                :clearable="false"
                :picker-options="pickerOptions"
                :placeholder="$t('Comn_stocking_date')"
              ></el-date-picker>
            </el-col>
          </el-form-item>

          <el-form-item
            :label="$t('Pond_pls_stocked')"
            prop="post_larva_stocked"
            :error="
              ekmm__castErrorKeyToLang(
                ehm__errMessagesObject['post_larva_stocked']
              )
            "
          >
            <el-input
              v-model="formPond.post_larva_stocked"
              :placeholder="$t('Pond_pls_stocked')"
              max="1000000000"
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('initial_abw')"
            prop="initial_abw"
            :error="
              ekmm__castErrorKeyToLang(ehm__errMessagesObject['initial_abw'])
            "
          >
            <el-input-number
              v-model="formPond.initial_abw"
              :placeholder="$t('initial_abw')"
              :controls="false"
            ></el-input-number>
          </el-form-item>

          <div class="btn-group">
            <er-button
              btnType="save"
              :showIcon="true"
              :showLabel="true"
              size="mini"
              @click="saveDetails"
              :disabled="isError"
            >
            </er-button>
            <er-button
              btnType="cancel"
              :showLabel="true"
              size="mini"
              @click="resetEditingMode"
            >
              {{ $t("Comn_cancel") }}
            </er-button>
          </div>
        </er-form>
      </el-card>
    </div>
  </div>
</template>

<script>
/**
 *TODO :
 *1.need to disable save button until an overlay is drawn
 *2.need to correct error of positioning name for the concave polygons
 **/

import Pond from "@/model/pond";
import { mapActions, mapGetters } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import errorKeyMapMixin from "@/mixins/errorKeyMapMixin";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import filtersMixin from "@/mixins/filtersMixin";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
import redirectsMixin from "@/mixins/redirectsMixin";

export default {
  mixins: [
    errorHandlerMixin,
    errorKeyMapMixin,
    datesHandlerMixin,
    userPreferenceMixin,
    filtersMixin,
    redirectsMixin
  ],
  props: ["map"],
  data: function () {
    return {
      formPond: new Pond(),
      pondDetailsDialog: false,
      currPondLocation: "",
      hoc: "",
      currentDate: "",
      cultivationendDate: "",
      pondLocationList: [],
      ehm__errMessagesObject: {
        title: "",
        location_id: "",
        size: "",
        name: "",
        post_larva_stocked: "",
        cultivation_date: "",
        initial_abw: ""
      },
      formName: "pondActivate",
      ekmm__backndfieldToFieldNameMap: {
        title: "Comn_pond_name",
        name: "Comn_location.case.lower",
        size: "Map_pond_size",
        post_larva_stocked: "Pond_pls_stocked",
        cultivation_date: "Comn_stocking_date",
        initial_abw: "Comn_initial_abw"
      },
      ekmm__backndfieldToInvalidMap: {
        size: { INVALID_VALUE: "Map_pond_size_greater0" },

        post_larva_stocked: {
          VALUE_SHOULD_BE_NUMBER: "pls_stocked_required",
          INVALID_VALUE: "pls_stocked_required",
          VALUE_SHOULD_BE_A_SAFE_NUMBER: "pls_stocked_safe_number"
        },
        cultivation_date: {
          INVALID_VALUE: "ABW_date_field_not_empty",
          DATE_SHOULD_BE_GREATER_THAN_PAST_CULTIVATION_DATE:
            "Date_should_be_greater_than_past_cultivation_date"
        },

        initial_abw: {
          INVALID_VALUE: "Comn_initial_abw_required",
          VALUE_SHOULD_BE_NUMBER: "Comn_initial_abw_greater_than_zero"
        },
        title: {
          FIELD_REQUIRED: "Comn_title_required"
        }
      },
      isError: false,
      actualPondSizeAC: 0,
      actualPondSizeHA: 0,
      minArea: 0,
      maxArea: this.maxArea,
    };
  },

  computed: {
    ...mapGetters("googleMaps", {
      currentPond: "getCurrentHoverPond",
      mapMode: "getMapMode"
    }),
    ...mapGetters("user", {
      getCurrUserLocation: "getCurrUserLocation",
      getUserLocationsObj: "getUserLocationsObj",
      getUserProfile: "getUserProfile",
      getUserCountryName: "getUserCountryName",
      getPreferredUnits: "getPreferredUnits"
    }),
    units: function () {
      return {
        ha: this.$t("Pond_size_units_ha"),
        ac: this.$t("Pond_size_units_ac")
      };
    },
    drawPondLocations: {
      get() {
        return this.pondLocationList;
      },
      set(value) {
        this.pondLocationList = value;
      }
    },

    getPondSize() {
      const size = Number(
        this.upm__applyConversion(
          +this.formPond.size,
          "ha",
          this.formPond.units
        )
      );
      return +Number(size).toFixed(2);
    },
    pickerOptions: function () {
      const { cultivations = [] } = this.formPond;
      return {
        disabledDate: (time) => {
          if (cultivations.length === 0) {
            return time.getTime() > Date.now();
          }
          const lastCultivation = cultivations[cultivations.length - 1];
          const lastCultivationEndDate = this.dhm__dateUtilsLib.getTime(
            this.dhm__dateUtilsLib.parse(
              lastCultivation.end_date,
              this.dhm__dateUtilsLib.isoFormatString,
              new Date()
            )
          );
          return (
            lastCultivationEndDate >= time.getTime() ||
            time.getTime() > Date.now()
          );
        }
      };
    }
  },
  watch: {
    currentPond: {
      handler: function (newVal, oldVal) {
        if (this.mapMode === "ACTIVATING") {
          this.formPond = newVal;
          console.log(newVal, oldVal);
        }
      }
    },
    "formPond.size"(newVal, oldVal) {
      if (parseFloat(oldVal) === 0 ) {
        if (!this.actualPondSizeAC > 0) {
          if (this.getPreferredUnits.pond_area_units === 'ha') {
            this.actualPondSizeAC = parseFloat((newVal * 2.47105).toFixed(3))
          }
          if (this.getPreferredUnits.pond_area_units === 'ac') {
            this.actualPondSizeAC = parseFloat(newVal.toFixed(3))
          }
        }
      }
      this.actualPondSizeHA = parseFloat((this.actualPondSizeAC / 2.47105).toFixed(3));
    }
  },
  mounted() {
    this.initComponent();
  },
  methods: {
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    initComponent() {
      this.formPond = this.currentPond;
      const cultivations = this.formPond.cultivations;
      this.hoc = this.formPond.cultivations.length
        ? this.dhm__formatByTZOnUTCWithLocale(
            this.formPond.cultivations[cultivations.length - 1].end_date,
            "yyyy-MM-dd"
          )
        : "";
      this.formPond.size = this.$commonUtils.castPondAreaSize(
          +this.formPond.size,
          this.currentPond.units,
          this.getPreferredUnits.pond_area_units
        );
      this.formPond.units = this.getPreferredUnits.pond_area_units;
      this.currPondLocation =
        this.getUserLocationsObj[this.formPond.location_id].name;
      this.drawPondLocations = this.getUserLocationsObj;
      const date = this.dhm__formatTZ(
        this.dhm__dateUtilsLib.utcToZonedTime(
          this.dhm__castUserUTCToUTCDateObj(this.dhm__getTodayInUserTZ),
          this.getUserTimeZoneString
        ),
        "yyyy-MM-dd"
      );
      this.cultivationendDate = date;
      this.ehm__errMessagesObject = {
        title: "",
        location_id: "",
        size: "",
        name: "",
        post_larva_stocked: "",
        cultivation_date: "",
        initial_abw: ""
      };
    },
    ekmm__errorLangKeyMapper: function (key, error_code) {
      if (error_code === "VALUE_SHOULD_BE_A_SAFE_NUMBER") {
        return this.$t(this.ekmm__backndfieldToInvalidMap[key][error_code]);
      } else if (error_code === "INVALID_VALUE") {
        return this.$t(this.ekmm__backndfieldToInvalidMap[key][error_code]);
      } else if (error_code === "VALUE_SHOULD_BE_NUMBER") {
        return this.$t(this.ekmm__backndfieldToInvalidMap[key][error_code]);
      } else if (error_code === "FIELD_REQUIRED") {
        return this.$t(this.ekmm__backndfieldToInvalidMap[key][error_code]);
      } else if (
        error_code === "DATE_SHOULD_BE_GREATER_THAN_PAST_CULTIVATION_DATE"
      ) {
        return this.$t(this.ekmm__backndfieldToInvalidMap[key][error_code]);
      }
    },

    resetEditingMode: function (event = null) {
      this.ehm__errMessagesObject = {
        title: "",
        location_id: "",
        size: "",
        name: "",
        post_larva_stocked: "",
        cultivation_date: "",
        initial_abw: ""
      };

      const pondId = this.map.currentOverlay.get("pond_id");
      const overlay = this.map.overlays.get(pondId);
      if (overlay && event != null) {
        this.map.setMap(overlay, true);
      }
      if (this.map.currentOverlay != null) {
        this.map.setMap(this.map.currentOverlay, false);
        this.map.currentOverlay = null;
      }
      this.map.mapData.revertStyle();
      this.map.removeControls("TOP_RIGHT");
      this.$store.dispatch("googleMaps/setMapMode", "INITIAL");
      this.map.addAllListeners();
      this.area = 0;
    },
    handleSizeChange(pondSize) {
      // this.formPond.size = this.$commonUtils.castPondAreaSize(
      //   Number(pondSize),
      //   this.formPond.units,
      //  this.getPreferredUnits.pond_area_units
      // ).toFixed(1);
      this.formPond.size = Number(pondSize);
      // if (this.formPond.units === 'ha') {
      //   this.checkMaximumPondSize(Number(pondSize), this.actualPondSizeHA)
      // } else {
      //   this.checkMaximumPondSize(Number(pondSize), this.actualPondSizeAC)
      // }
    },
    checkMaximumPondSize(value, actualPondSize) {
      const minArea = parseFloat((actualPondSize - (actualPondSize / 100) * 20).toFixed(2).slice(0, -1));
      const maxArea = parseFloat((actualPondSize + (actualPondSize / 100) * 20).toFixed(2).slice(0, -1));
      console.log("actualPondSize", actualPondSize, value, maxArea, minArea);
      if (parseFloat(value.toFixed(2).slice(0, -1)) > maxArea || parseFloat(value.toFixed(2).slice(0, -1)) < minArea) {
        this.isError = true;
        this.minArea = minArea;
        this.maxArea = maxArea;
      } else {
        this.isError = false;
      }
      return this.maxArea;
    },
    handleChangeAreaUnits(newUnitsSelected) {
      this.formPond.units = newUnitsSelected;
      if (this.formPond.units === "ha") {
        const size = +Number(
          this.$commonUtils.castPondAreaSize(
            +this.formPond.size,
            "ac",
            this.formPond.units
          )
        );
        this.formPond.size = parseFloat(size.toFixed(3));
      } else {
        const size = +Number(
          this.$commonUtils.castPondAreaSize(
            +this.formPond.size,
            "ha",
            this.formPond.units
          )
        );
        this.formPond.size = parseFloat(size.toFixed(3));
      }
      // if (newUnitsSelected === 'ha') {
      //   this.checkMaximumPondSize(this.formPond.size, this.actualPondSizeHA)
      // } else {
      //   this.checkMaximumPondSize(this.formPond.size, this.actualPondSizeAC)
      // }
    },
    handleChangeInLocation(value) {
      this.currPondLocation = value.toLowerCase();
    },
    ehm__error409Handler: function (err) {
      if (err.response.data.error_code === "POND_TITLE_ALREADY_TAKEN") {
        this.$notify({
          title: this.$t("failed"),
          message: this.$t("Ponds_name_already_in_use_choose_different"),
          type: "error",
          duration: 4500
        });
      } else {
        this.$notify({
          title: this.$t("failed"),
          message: this.$t("Something_went_wrong"),
          type: "error",
          duration: 4500
        });
      }
    },
    saveDetails: async function () {
      const loading = this.$loading({ fullscreen: true });
      try {
        this.ehm__errMessagesObject = {
          title: "",
          location_id: "",
          size: "",
          name: "",
          post_larva_stocked: "",
          cultivation_date: "",
          initial_abw: ""
        };
        let isNewLocation = false;
        const arrLocations = Object.values(this.getUserLocationsObj);
        const foundPlaceIndex = arrLocations
          .map((x) => x.name)
          .indexOf(this.currPondLocation.toLowerCase());
        let location;
        if (foundPlaceIndex === -1) {
          location = await this.$store.dispatch("user/createLocation", {
            name: this.currPondLocation.toLowerCase()
          });
          isNewLocation = true;
        } else {
          location = arrLocations[foundPlaceIndex];
        }
        this.formPond.location_id = location._id;
        // this.formPond.units = this.formPond.units === "ac" ? "ha" : "ha";
        // adding timezone to the formPond object
        this.formPond.timezone = this.getUserProfile.timezone;
        const errorgenerator = (error_code, error_key) => {
          throw {
            response: {
              status: 422,
              data: {
                errors: {
                  details: [{ error_code: error_code, key: error_key }]
                }
              }
            }
          };
        };

        if (this.formPond.initial_abw === undefined) {
          errorgenerator("INVALID_VALUE", "initial_abw");
        } else if (this.formPond.initial_abw <= 0) {
          errorgenerator("VALUE_SHOULD_BE_NUMBER", "initial_abw");
        } else if (this.cultivationendDate === this.hoc) {
          errorgenerator(
            "DATE_SHOULD_BE_GREATER_THAN_PAST_CULTIVATION_DATE",
            "cultivation_date"
          );
        } else {
          const payload = {
            title: this.trim_nameSpace(this.formPond.title),
            pond_id: this.formPond._id,
            pond_coordinates: this.formPond.pond_coordinates,
            timezone: this.formPond.timezone,
            location_id: this.formPond.location_id,
            size: this.formPond.size || null,
            units: this.formPond.units,
            post_larva_stocked: +this.formPond.post_larva_stocked,
            cultivation_date: this.cultivationendDate,
            initial_abw: this.formPond.initial_abw,
            status: "ACTIVE"
          };
          await this.$store.dispatch("pond/updatePondDetails", payload);
          this.$notify({
            title: this.$t("Usrs_success_msg"),
            message: this.$t("Pond_details_save_success"),
            duration: 5000,
            type: "success"
          });
          this.mixPanelEventGenerator({ eventName: "Map - Activate Pond - Pond Details - Save" });
          loading.close();
          if (isNewLocation) {
            this.$store.dispatch("user/fetchUserLocations");
            this.$store.dispatch("user/updateCurrUserLocation", location);
            this.rdm__navigateToPathWithLocationId({
              query: { ...this.$route.query, location_id: location._id }
            });
          } else if (
            this.getCurrUserLocation._id !== this.currPondLocation._id
          ) {
            this.$store.dispatch("user/updateCurrUserLocation", location);
            this.rdm__navigateToPathWithLocationId({
              query: { ...this.$route.query, location_id: location._id }
            });
          }
          this.$emit("refetch_data");
        }
      } catch (err) {
        // this.initComponent();
        this.ehm__errorMessages(err, true);
      } finally {
        loading.close();
      }
    }
  }
};
</script>

<style lang="scss">
#edit-pond {
  position: absolute;
  top: 0px;
  left: 0px;
  .edit-pond-input-size {
    width: 100%;
    .el-input__inner {
      text-align: left;
    }
  }
  .action-display-panel {
    display: flex;
    flex-direction: column;
    align-content: center;
    &.el-card {
      margin: 10px;
      @include normal-text;
    }
    .btn-group {
      margin-top: 10px;
      display: flex;
      justify-content: center;
      .el-button {
        font-size: inherit;
      }
    }
    .action-display-area-input {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }
    .el-input {
      width: 100%;
      font-size: inherit;
    }
    .el-card__header,
    .el-card__body {
      padding: 10px 10px;
    }
    .input-label {
      display: inline-block;
      width: fit-content;
      margin: auto;
    }
    .select-location {
      .el-input__inner {
        text-transform: capitalize;
      }
    }
  }
}
.action-display-panel {
  background: #fcfcfc;
  border-radius: 10px;

  .el-input--small .el-input__inner {
    @include responsiveProperty(width, 185px, 190px, 250px);
  }

  .el-form-item--small .el-form-item__content,
  .el-form-item--small .el-form-item__label {
    @include responsiveProperty(
      font-size,
      $app_font_size_1,
      $app_font_size_2,
      $app_font_size_3
    );
    @include responsiveProperty(min-width, 100px, 125px, 150px);
  }
  .error-msg {
    color: #f56c6c;
    font-size: 12px;
    line-height: 1.5;
    margin-bottom: -10px;
  }
}
</style>
