<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: pondsList.vue
Description: This file contains UI components used show ponds in the left panel in PondMother Schedules page
-->
<template>
  <transition name="el-zoom-in-top" @enter="handleScrollToOption">
    <ul class="ponds-list">
      <el-scrollbar
        class="pond-list-scroll"
        viewClass="pond-list-scroll-view"
        wrapClass="pond-list-scroll-wrap"
      >
        <li class="ponds-list__item ponds-list__item-header small-text">
          <slot name="list-header">
            <el-card shadow="never">
              <el-row>
                <el-col>All Ponds</el-col>
              </el-row>
            </el-card>
          </slot>
        </li>
        <template v-if="listOfPonds.length > 0">
          <li
            v-for="(pond, index) in listOfPonds"
            :key="index"
            :ref="'pondsListItem_' + pond[uniqueIdKey]"
            :class="[
              'ponds-list__item',
              selectPondId === pond[uniqueIdKey] ? 'selected' : '',
              listOfPonds.length === index + 1 ? 'ponds-list__item-footer' : '',
              'small-text',
            ]"
            @click="
              handleItemClick(
                $event,
                pond,
                'pondsListItem_' + pond[uniqueIdKey]
              )
            "
          >
            <slot name="list-item" :pond="pond">
              <pond-list-item
                :pond-object="pond"
                :unique-id-key="uniqueIdKey"
              ></pond-list-item>
            </slot>
          </li>
        </template>
        <li v-else class="ponds-list__item ponds-list__item-empty">
          <el-card shadow="never">
            <slot name="empty-state">
              <layout-center>
                <el-tag class="small-text">{{ $t("Comn_no_ponds") }}</el-tag>
              </layout-center>
            </slot>
          </el-card>
        </li>
      </el-scrollbar>
    </ul>
  </transition>
</template>

<script>
import pondListItem from "./pondListItem";
import scrollIntoView from "element-ui/src/utils/scroll-into-view";
export default {
  components: {
    pondListItem
  },
  props: {
    listOfPonds: {
      default: () => []
    },
    uniqueIdKey: {
      required: true
    },
    value: {
      required: true
    }
  },
  data: function () {
    return {
      selectedItemDomNode: undefined
    };
  },
  computed: {
    selectPondId() {
      return this.value[this.uniqueIdKey];
    }
  },
  mounted() {
    this.handleScrollToOption();
  },
  methods: {
    handleItemClick($event, selectedPond, refId) {
      this.handleScrollToOption();
      this.$emit("click", selectedPond);
    },
    handleScrollToOption() {
      this.$nextTick(() => {
        this.selectedItemDomNode =
          this.$refs[`pondsListItem_${this.value[this.uniqueIdKey]}`];
        if (this.selectedItemDomNode && this.selectedItemDomNode[0]) {
          scrollIntoView(
            this.$el.querySelector(".pond-list-scroll-wrap"),
            this.selectedItemDomNode[0]
          );
        }
      });
    }
  }
};
</script>

<style lang="scss">
.ponds-list {
  $list-item-height: 35px;
  height: 100%;
  .pond-list-scroll {
    height: 100%;
    // padding-bottom: 10px;
  }
  .pond-list-scroll-wrap {
    overflow: auto;
  }
  .pond-list-scroll-view {
    padding-right: 10px;
    padding-bottom: 10px;
  }
  .ponds-list__item {
    list-style: none;
    font-weight: 500;
    &.ponds-list__item-header {
      position: sticky;
      top: 0px;
      z-index: 10;
    }
  }
}
</style>
