<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: pondBasicModeCollapseItem.vue
Description: This file contains UI components used to display collapsable pond in basic mode in PondMother Schedules page - Pond Level
-->
<template>
  <el-row
    justify="center"
    v-loading="loading"
    element-loading-background="white"
  >
    <el-row>
      <el-row type="flex" justify="center">
        <el-alert
          :title="$t('Comn_alerts')"
          type="error"
          @close="ehm__handleAlertClose"
          v-if="ehm__unhandledErrorMessage !== ''"
          show-icon
          >{{ ehm__unhandledErrorMessage }}</el-alert
        >
      </el-row>
    </el-row>
    <el-row>
      <el-col :span="16">
        <el-form class="mode-collapse-item">
          <el-table
            :data="tableData"
            size="mini"
            :indent="10"
            :border="false"
            :stripe="false"
            class="card-row-table"
            header-row-class-name="card-row-table__header-row"
            :row-class-name="functionPondTSTableRowClassName"
          >
            <template slot="empty">
              <table-empty-state></table-empty-state>
            </template>
            <el-table-column
              :width="tableColumns.total_feed_kgs.width"
              header-align="center"
              align="center"
              :label="tableColumns.total_feed_kgs.label"
            >
              <template slot-scope="scope">
                <el-input
                  type="number"
                  size="mini"
                  v-model="scope.row.feed"
                  :min="0"
                  title
                  :step="0.01"
                  :precision="2"
                  :disabled="disableTotalFeed(scope.row)"
                  :controls="false"
                  @change="
                    handleChangeInput(
                      $event,
                      scope.$index,
                      scope.row,
                      'total_feed_kgs'
                    )
                  "
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              :width="tableColumns.ocf_g.width"
              header-align="center"
              align="center"
              :label="tableColumns.ocf_g.label"
            >
              <template slot-scope="scope">
                <el-input
                  type="number"
                  size="mini"
                  v-model="scope.row.ocf"
                  :min="100"
                  :step="50"
                  :precision="0"
                  :disabled="disableOCF(scope.row)"
                  :controls="false"
                  @change="
                    handleChangeInput($event, scope.$index, scope.row, 'ocf_g')
                  "
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              :width="tableColumns.dispensed_feed.width"
              header-align="center"
              align="center"
              :label="tableColumns.dispensed_feed.label"
            >
              <template slot-scope="scope">
                <el-input
                  type="number"
                  size="mini"
                  :value="scope.row.dispensed_feed"
                  :precision="0"
                  :disabled="true"
                  :controls="false"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              :width="tableColumns.actions.width"
              header-align="center"
              align="center"
            >
              <template slot-scope="scope">
                <el-row type="flex" align="middle">
                  <template v-if="!hidePondStatusActionBtns(scope.row)">
                    <er-button
                      v-if="scope.row.ui_status === POND_TS_STATUS.RUNNING"
                      size="mini"
                      btnType="pause"
                      :showIcon="true"
                      class="btn-actions"
                      :disabled="isReadOnly"
                      @click="
                        handleStatusChange(
                          $event,
                          scope.$index,
                          scope.row,
                          POND_TS_STATUS.PAUSED
                        )
                      "
                    ></er-button>
                    <er-button
                      v-if="scope.row.ui_status === POND_TS_STATUS.PAUSED"
                      size="mini"
                      btnType="play"
                      :showIcon="true"
                      class="btn-actions"
                      :disabled="isReadOnly"
                      @click="
                        handleStatusChange(
                          $event,
                          scope.$index,
                          scope.row,
                          POND_TS_STATUS.RUNNING
                        )
                      "
                    ></er-button>
                    <er-button
                      v-if="
                        [POND_TS_STATUS.RUNNING, POND_TS_STATUS.PAUSED].indexOf(
                          scope.row.ui_status
                        ) > -1
                      "
                      size="mini"
                      class="btn-actions"
                      btnType="stop"
                      :showIcon="true"
                      :disabled="isReadOnly"
                      @click="
                        handleStatusChange(
                          $event,
                          scope.$index,
                          scope.row,
                          POND_TS_STATUS.STOPPED
                        )
                      "
                    ></er-button>
                  </template>
                </el-row>
              </template>
            </el-table-column>
          </el-table>
          <el-row
            type="flex"
            class="save-btn-container"
            v-if="!hideAddSaveBtnsAtPondLevel"
          >
            <el-col :span="6">
              <er-button
                size="mini"
                btnType="save"
                :showLabel="true"
                :showIcon="true"
                :disabled="isReadOnly"
                @click="handleSaveSchedule"
              ></er-button>
            </el-col>
          </el-row>
        </el-form>
      </el-col>
      <el-col class="add-btn-container" :span="8">
        <el-row type="flex" justify="end" v-if="!hideAddSaveBtnsAtPondLevel">
          <er-button
            size="mini"
            btnType="add"
            :disabled="disabledAddScheds()"
            icon="el-icon-circle-plus-outline"
            :showLabel="true"
            @click="handleAddTS"
          ></er-button>
        </el-row>
      </el-col>
    </el-row>
  </el-row>
</template>
<script>
import pmSchedulesMixin from "@/mixins/pmSchedulesMixin.js";
import { mapGetters, mapActions, mapMutations } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import { POND_TS_STATUS } from "@/constants/schedule";
export default {
  mixins: [pmSchedulesMixin, errorHandlerMixin],
  props: ["pond"],
  data: function () {
    return {
      pastPickedTime: [],
      timePickerOptions: {
        format: "HH:mm"
      },
      ehm__errMessagesObject: {},
      loading: false,
      POND_TS_STATUS
    };
  },

  computed: {
    ...mapGetters("pondMotherSchedules", {
      getSelectedDay: "getSelectedDay",
      getPondTSTableData: "getPondTSTableData",
      getDateRange: "getDateRange",
      getRemainingTSToPondId: "getRemainingTSToPondId"
    }),
    tableData: function () {
      return this.$lodash.cloneDeep(Object.values(this.tableDataObj || {}));
    },
    tableDataObj: function () {
      return this.getPondTSTableData[this.selectedDay + "_" + this.pondId];
    },
    selectedDay: function () {
      return this.getSelectedDay;
    },
    pondId: function () {
      return this.pond._id;
    },
    canAddASchedule: function () {
      return (scope) => {
        const tableDataLength = this.tableData.length;
        return (
          tableDataLength < 8 &&
          (tableDataLength === 1 || scope.$index === tableDataLength - 1)
        );
      };
    },
    dateRange: function () {
      return this.getDateRange;
    },
    dateRange0: function () {
      return this.dateRange[0];
    },
    dateRange1: function () {
      return this.dateRange[1];
    }
  },
  methods: {
    ...mapActions("pondMotherSchedules", {
      updatePondTSTime: "updatePondTSTime",
      updatePondTSFeedConfigParams: "updatePondTSFeedConfigParams",
      addPondTSToPondTSTableData: "addPondTSToPondTSTableData",
      deletePondTSFromPondTSTable: "deletePondTSFromPondTSTable",
      savePondLevelSchedule: "savePondLevelSchedule"
    }),
    ...mapMutations("pondMotherSchedules", {
      CHECK_VALID_POND_DATA_TO_SAVE: "CHECK_VALID_BASIC_MODE_POND_DATA_TO_SAVE"
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    commonDisableCasesForAllFields: function (ts, field_name) {
      const pmkeys = Object.keys(ts.pmsSubscribe);
      const casesToDisable = [
        !ts.enabled[field_name],
        [
          POND_TS_STATUS.COMPLETED,
          POND_TS_STATUS.UI_COMPLETED,
          POND_TS_STATUS.PM_DIFF_STATUS,
          POND_TS_STATUS.STOPPED
        ].indexOf(ts.ui_status) > -1,
        pmkeys.length < this.pmsLength
      ];
      return casesToDisable.includes(true);
    },
    disabledAddScheds() {
      const scheduleCount = this.getRemainingTSToPondId(
        this.pondId,
        this.selectedDay
      );
      console.log(scheduleCount);
      if (scheduleCount >= 1) return true;
      if (this.isReadOnly) return true;
      return false;
    },
    disableToTime(pondTS) {
      const casesToDisable = [
        this.commonDisableCasesForAllFields(pondTS, "to_time")
      ];
      if (casesToDisable.includes(true)) {
        return true;
      } else {
        return false;
      }
    },
    async handleAddTS(event) {
      try {
        this.$gblUAMCanUserEdit(this.permissions);
        const presentPondTS = this.tableData[this.tableData.length - 1];
        await this.addPondTSToPondTSTableData({
          pondId: this.pondId,
          selectedDay: this.selectedDay,
          pmsObj: this.getSelectedPmsObjWithDetails,
          siblingTSId: presentPondTS.ui_id,
          mode: "MANUAL"
        });
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }
    },
    disableTotalFeed(pondTS) {
      const casesToDisable = [
        this.commonDisableCasesForAllFields(pondTS, "feed")
      ];
      if (casesToDisable.includes(true)) {
        return true;
      } else {
        return false;
      }
    },
    disableFeedGap(pondTS) {
      const casesToDisable = [
        this.commonDisableCasesForAllFields(pondTS, "feed_gap")
      ];
      if (casesToDisable.includes(true)) {
        return true;
      } else {
        return false;
      }
    },
    disableOCF(pondTS) {
      const casesToDisable = [
        this.commonDisableCasesForAllFields(pondTS, "ocf")
      ];
      if (casesToDisable.includes(true)) {
        return true;
      } else {
        return false;
      }
    },
    handleDeleteTS(event, index, presentPondTS) {
      this.deletePondTSFromPondTSTable({
        selectedDay: this.selectedDay,
        pondId: this.pondId,
        pondTSId: presentPondTS.ui_id
      });
    },
    handleChangeTPicker(updtPropVal, changedTSObj, propertyName) {
      try {
        const currTSId = changedTSObj.ui_id;
        this.updatePondTSTime({
          prop: propertyName,
          value: updtPropVal,
          selectedDay: this.selectedDay,
          currTSId,
          pondId: this.pondId
        });
      } catch (err) {
        console.error(err);
      }
    },
    handleChangeInput(updatedValue, posInTable, changedTSObj, propertyName) {
      // console.log(event, propertyName);
      const currTSId = changedTSObj.ui_id;
      this.updatePondTSFeedConfigParams({
        prop: propertyName,
        value: updatedValue,
        selectedDay: this.selectedDay,
        currTSId,
        pondId: this.pondId
      });
    },
    async handleStatusChange(event, indexInTable, changedTSObj, newStatus) {
      try {
        this.$gblUAMCanUserEdit(this.permissions);
        await this.TSStatusChangeConfirm(newStatus);
        this.loading = true;
        await this.changeStatusOfPondTS({
          ui_id: this.tableData[indexInTable].ui_id,
          pondId: this.pondId,
          status: newStatus,
          mode: "MANUAL"
        });
        this.notifyScheduleSuccess("status");
        this.mixPanelEventGenerator({ eventName: "Feeding - PondMother - Pond Collapse - " + newStatus + " Schedules" });
      } catch (err) {
        if (err !== "cancel") {
          this.ehm__errorMessages(err, true);
        }
      } finally {
        this.loading = false;
      }
      console.log(newStatus, this.tableData[indexInTable].bk_id, this.pondId);
    },
    async handleSaveSchedule() {
      try {
        this.$gblUAMCanUserEdit(this.permissions);
        this.CHECK_VALID_POND_DATA_TO_SAVE({
          selectedDay: this.selectedDay,
          pondId: this.pondId
        });
        await this.checkWhetherToSaveSameSchedForRemainingDays();
        this.loading = true;
        await this.savePondLevelSchedule();
        this.notifyScheduleSuccess("saved");
        this.mixPanelEventGenerator({ eventName: "Feeding - PondMother - Pond Collapse - Save Schedules" });
      } catch (err) {
        this.errorFailToSaveHandler(err);
      } finally {
        this.loading = false;
      }
    },
    ehm__error422Handler: function (err) {
      const errors = err.response.data.errors;
      let message =
        err.response.data.message || this.$t("Something_went_wrong_try_again");
      if (
        errors &&
        Array.isArray(errors.details) &&
        errors.details.length > 0
      ) {
        message = errors.details[0].message;
      }
      this.ehm__errorFailToSave([{ message }]);
    }
  }
};
</script>

<style lang="scss"></style>
