<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: pondScheduleDetails.vue
Description: This file contains UI components that can be used to view and update PondMother schdeules
-->
<template>
  <el-scrollbar
    viewClass="pond-schedule-details-scroll-view"
    wrapClass="pond-schedule-details-scroll-wrap"
    class="pond-mother-schedules-view"
  >
    <div v-if="getIsPondSchedLoading">
      <loader></loader>
    </div>
    <template v-else-if="hasPonds && hasPms">
      <layout-toolbar class="schedule-container">
        <el-tabs
          size="mini"
          type="card"
          :value="'' + getSelectedDay"
          :active-name="'' + getSelectedDay"
          @tab-click="handleDayTabClick($event)"
        >
          <el-tab-pane
            v-for="(date, index) in getArrOfDaysFromDateRange"
            :key="'pond-date-' + index"
            :label="getTabName(date)"
            :name="'' + date"
          ></el-tab-pane>
        </el-tabs>
        <div class="filler"></div>
        <er-date-picker
          type="daterange"
          size="mini"
          :value="getDateRange"
          :timeZoneString="getUserTimeZoneString"
          value-format="yyyy-MM-dd"
          range-separator="To"
          :format="this.upm__getFormatDateString"
          :arrShortcuts="arrShortcuts"
          :disableDateMethod="disableDateMethod"
          :clearable="false"
          :editable="false"
          popper-class="pm-schedule-date-picker-popper"
          class="pm-schedule-date-picker"
          @input="callStoreToFetchData"
        ></er-date-picker>
      </layout-toolbar>
      <el-row>
        <pond-collapse></pond-collapse>
      </el-row>
    </template>
    <el-card v-else>
      <layout-center height="14vh">
        <el-row type="flex" align="center" justify="center">
          <el-tag type="error" style="font-size: 1em">
            <center class="small-text">
              <template v-if="!hasPonds">
                <i18n
                  path="pond_are_inactive_make_them_active"
                  tag="label"
                  for="Comn_activate_the_pond"
                >
                  <a href="/user/settings?tab=pondTab">
                    {{ $t("Comn_activate_the_pond") }}
                  </a>
                </i18n>
              </template>
              <template v-else-if="!hasPms">
                <i18n
                  path="no_feeders_available_or_pond_dont_have_feeders"
                  tab="label"
                  for="assign_pondmothers"
                ></i18n>
                <a href="/user/settings?tab=devices">
                  {{ $t("assign_pondmothers") }}
                </a>
              </template>
              <template v-else>{{ $t("PM_no_sch_found") }}</template>
            </center>
          </el-tag>
        </el-row>
      </layout-center>
    </el-card>
  </el-scrollbar>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import pondCollapse from "@/components/pmSchedules/pondCollapse";
import { DATE_OBJ_DATE_FORMAT_WITH_YEAR } from "@/utils/commonUtils";
import Loader from "@/components/base/Loader";
import pmSchedulesMixin from "@/mixins/pmSchedulesMixin";
import dateUtils from "@/utils/dateUtils";
export default {
  provide() {
    return {
      pmSchedulesData: this.$data
    };
  },
  mixins: [errorHandlerMixin, pmSchedulesMixin],
  components: {
    pondCollapse,
    Loader
  },
  data: function() {
    return {
      dateRange: [],
      SchedulesObj: {},
      mapPmIdTimeSlots: {},
      mapPondIdTimeSlots: {},
      dayQueryType: "TODAY",
      DATE_OBJ_DATE_FORMAT_WITH_YEAR
    };
  },
  computed: {
    ...mapGetters("pondMotherSchedules", {
      location: "getCurrUserLocation",
      getPondDetailsToPondId: "getPondDetailsToPondId",
      getExistSchedDtSecsToPondIdToPondSchedDtls:
        "getExistSchedDtSecsToPondIdToPondSchedDtls",
      getArrOfDaysFromDateRange: "getArrOfDaysFromDateRange",
      getDateRange: "getDateRange",
      getSelectedDay: "getSelectedDay",
      getIsPondSchedLoading: "getIsPondSchedLoading"
    }),
    ...mapGetters("pondmother", {
      getObjPmIdPm: "getObjPmIdPm"
    }),
    ...mapGetters("user", {
      getCurrUserType: "getCurrUserType",
      getUserTimeZoneString: "getUserTimeZoneString"
    }),
    hasPonds() {
      return Object.keys(this.getPondDetailsToPondId || {}).length > 0;
    },
    hasSchedules() {
      return (
        Object.keys(this.getExistSchedDtSecsToPondIdToPondSchedDtls || {})
          .length > 0
      );
    },

    hasPms() {
      return Object.keys(this.getAllPondsPmDetailsToPmId || {}).length > 0;
    },
    getDayTimeInSecs() {
      return this.$commonUtils.DAY_TIME_IN_MS;
    },
    currentDate() {
      return dateUtils.getCurrDateInGivenTZ(this.getUserTimeZoneString);
    },
    arrShortcuts: function() {
      return [
        this.dhm__dateUtilsLib.getDatePickerShortcut(
          -1,
          "days",
          this.$t("Comn_today"),
          this.currentDate,
          "TODAY"
        ),
        this.dhm__dateUtilsLib.getDatePickerShortcut(
          1,
          "days",
          this.$t("tomorrow"),
          this.currentDate,
          "FUTURE"
        ),

        this.dhm__dateUtilsLib.getDatePickerShortcut(
          2,
          "days",
          undefined,
          this.currentDate,
          "FUTURE"
        ),
        this.dhm__dateUtilsLib.getDatePickerShortcut(
          3,
          "days",
          undefined,
          this.currentDate,
          "FUTURE"
        ),
        this.dhm__dateUtilsLib.getDatePickerShortcut(
          4,
          "days",
          undefined,
          this.currentDate,
          "FUTURE"
        ),
        this.dhm__dateUtilsLib.getDatePickerShortcut(
          5,
          "days",
          undefined,
          this.currentDate,
          "FUTURE"
        ),
        this.dhm__dateUtilsLib.getDatePickerShortcut(
          6,
          "days",
          undefined,
          this.currentDate,
          "FUTURE"
        ),
        this.dhm__dateUtilsLib.getDatePickerShortcut(
          14,
          "days",
          undefined,
          this.currentDate,
          "FUTURE"
        )
      ];
    }
  },
  methods: {
    ...mapActions("pondMotherSchedules", {
      fetchAllPondDetails: "fetchAllPondDetails",
      fetchAllSchedDetails: "fetchAllSchedDetails",
      fetchAllPmDetails: "fetchAllPmDetails",
      changeDateRange: "changeDateRange",
      initPondCollapseTableData: "initPondCollapseTableData",
      clearStoreState: "clearStoreState",
      changeDateQueryType: "changeDateQueryType",
      refreshPondSchedules: "refreshPondSchedules"
    }),
    ...mapMutations("pondMotherSchedules", {
      INIT_DATE_RANGE: "INIT_DATE_RANGE",
      INIT_SELECTED_DAY: "INIT_SELECTED_DAY"
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    getTabName(date) {
      return this.dhm__formatOnDateObjWithLocale(date, "dd MMM");
    },
    async callStoreToFetchData(changedDates) {
      try {
        const dateQueryType = this.getValidDateQueryType(
          ...changedDates,
          "yyyy-MM-dd"
        );
        this.INIT_DATE_RANGE(changedDates);
        this.changeDateQueryType(dateQueryType);
        this.dateRange = this.getDateRange;
        const isoDateString = `${changedDates[0]}T00:00:00.000Z`;
        const s_date = this.dhm__dateUtilsLib.utcToZonedTime(
          this.dhm__castUserUTCToUTCDateObj(isoDateString),
          this.getUserTimeZoneString
        );
        const s_date_secs = s_date.getTime();
        if (this.getSelectedDay !== s_date_secs) {
          const prevSelectedDay = this.getSelectedDay;
          this.INIT_SELECTED_DAY(s_date_secs);
          await this.refreshPondSchedules({
            days: [prevSelectedDay]
          });
        }
        this.mixPanelEventGenerator({ eventName: "Feeding - PondMother - Date Filter" });
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }
    },
    async handleDayTabClick($event) {
      try {
        const prevSelectedDay = this.getSelectedDay;
        this.INIT_SELECTED_DAY(+$event.paneName);
        await this.refreshPondSchedules({
          days: [prevSelectedDay]
        });
        this.mixPanelEventGenerator({ eventName: "Feeding - PondMother - Day Selector" });
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }
    }
  }
};
</script>

<style lang="scss">
.pm-page-schedules-error-lists {
  padding-left: 15px;
  max-height: 100px;
  overflow-y: auto;
}
.pm-scheds-error-message-popper.hide {
  visibility: hidden;
  pointer-events: none;
}
.pond-mother-schedules-view {
  height: 100%;
  $font-color: #0a2463;
  .pond-schedule-details-scroll-wrap {
    // margin-right: 5px !important;
    padding-right: 8px;
    overflow: auto;
  }
  .save-btn-container {
    margin-left: 15px;
  }
  .header {
    color: #0a2463;
    font-size: 20px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 20px;
  }
  .date-class-container {
    display: flex;
    justify-content: flex-end;
  }
  .schedule-container {
    position: sticky;
    top: 0px;
    z-index: 1001;
    background-color: #ecf3f9;
    padding-bottom: 5px;
  }
  .el-input--mini,
  td .cell,
  th .cell {
    font-size: 13px;
  }
  .el-header {
    margin-bottom: unset;
  }
  .page-name {
    color: $font-color;
  }
  // .pm-schedule-date-picker {
  //   .el-range-separator {
  //     color: $font-color;
  //     @include small-text;
  //   }
  //   .el-range-input {
  //     // width: 45%;
  //     color: $font-color;
  //     font-size: 13px;
  //     @include small-text;
  //     background-color: transparent !important;
  //   }
  //   .el-range__close-icon,
  //   .el-range__icon {
  //     line-height: 1.5;
  //     @include icon-size(icon-font, prefix);
  //   }
  //   .el-input__icon.el-range__close-icon {
  //     display: none;
  //   }
  // }
  .is-disabled .el-input__inner {
    border: unset;
    background-color: white;
  }
  .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    color: #ffffff;
    background: $button-color;
    border-color: $button-color;
    box-shadow: -1px 0 0 0 $button-color;
  }
  .el-radio-button__orig-radio + .el-radio-button__inner {
    color: $button-color;
  }
  .mode-collapse-item {
    $table-row-box-shadow: #e3d1d1;
    .el-form-item {
      margin-bottom: 0px;
      &:before,
      &:after {
        display: unset;
        content: unset;
      }
      .el-form-item__content {
        line-height: 30px;
        &:before,
        &:after {
          display: unset;
          content: unset;
        }
        & > div {
          text-align: left;
        }
      }
    }
    .card-row-table {
      &.el-table th.is-leaf {
        border-bottom: none;
      }
      .card-row-table__header-row {
        color: #828282;
        .cell {
          text-align: left;
          @include responsiveProperty(
            font-size,
            $app_font_size_small,
            $app_font_size_1,
            $app_font_size_2
          );
        }
      }
      .card-row-table__row .cell {
        text-align: left;
        @include responsiveProperty(
          font-size,
          $app_font_size_small,
          $app_font_size_1,
          $app_font_size_2
        );
        .el-input .el-input__inner {
          color: #2b4c8e;
          // width: 100%;
          border: 1px solid #acc9f4;
          background-color: rgba(229, 236, 243, 0.2);
          text-align: center;
          @include responsiveProperty(
            font-size,
            $app_font_size_small,
            $app_font_size_1,
            $app_font_size_2
          );
        }
        .el-input.is-disabled {
          opacity: 0.6;
        }
        .el-input--prefix .el-input__inner {
          padding-left: 15px;
        }
        .el-input--suffix .el-input__inner {
          padding-right: 15px;
        }
      }
      td,
      .el-table th.is-leaf {
        border-bottom: unset;
      }
      .el-table__header-wrapper,
      .el-table__body-wrapper {
        margin-right: 5px;
        margin-left: 5px;
      }
    }
    .btn-schedules {
      margin: 5px 0px 15px 20px;
      position: relative;
      border-radius: 10px;
    }
  }
  .el-table tr.ts--completed,
  .el-table tr.ts--mismatched {
    pointer-events: none;
    opacity: 0.6;
  }
  .add-btn-container {
    padding: 6px 0px;
  }
  .el-tabs--card {
    max-width: calc(100% - var(--date-picker-width));
    & > .el-tabs__header {
      border-bottom: unset;
      margin: unset;
      .el-tabs__nav {
        border: unset;
      }
      .el-tabs__item {
        border-radius: 5px;
        display: inline-block;
        height: 28px;
        @include responsiveProperty(height, 28px, 31px, 34px);
        @include responsiveProperty(line-height, 28px, 31px, 34px);
        margin-left: 10px;
        padding: 0px 10px !important;
        text-align: center;
        background-color: white;
        @include responsiveProperty(
          font-size,
          $app_font_size_small,
          $app_font_size_1,
          $app_font_size_2
        );
        color: #19191999;
        &:last-child {
          margin-right: 10px;
        }
        &.is-active {
          background-color: #508ff4;
          border-bottom: unset;
          color: #ffffff;
        }
      }
    }
  }

  .el-tabs__nav-next,
  .el-tabs__nav-prev {
    line-height: 30px;
  }
  .el-tabs__nav-next:not(.is-disabled),
  .el-tabs__nav-prev:not(.is-disabled) {
    i {
      color: #508ff4;
    }
  }
  .el-tabs__nav-next .el-icon-arrow-right:before {
    content: "\e791";
    @include icon-size(icon-font, suffix);
  }
  .el-tabs__nav-prev .el-icon-arrow-left:before {
    content: "\e792";
    @include icon-size(icon-font, prefix);
  }
  .el-tabs__nav-wrap.is-scrollable {
    padding: 0px 15px;
  }
  .btn-actions {
    line-height: 0.8;
    & > span {
      display: inline-flex;
    }
    .material-icons-round {
      @include normal-text;
      font-weight: bolder;
      &:before {
        font-size: initial;
      }
    }
    &.el-button--mini {
      padding: 2px 2px 2px 2px;
      width: 28px;
    }
  }
}
</style>
