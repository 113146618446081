<template>
  <er-dialog
    class="pond-schedule-action-dialog-form"
    custom-class="pond-schedule-action-dialog"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    width="27%"
    :beforeClose="() => handleCloseDialog('cross-icon')"
    @open="handleOpenDialog"
    @opened="handleOpenedDialog"
    :title="`${ftm__capitalize(
      actionToDialogTitle[action.key] && actionToDialogTitle[action.key]()
    )}`"
  >
    <ValidationObserver ref="pond-schedule-action-dialog-form">
      <el-form size="small" :model="scheduleActionPayload">
        <ValidationProvider
          rules="required"
          :name="$tc('Comn_pond', 0)"
          v-slot="{ errors }"
        >
          <el-form-item
            :label="$tc('Comn_pond_name', 0)"
            prop="ponds"
            :error="errors[0]"
          >
            <er-select
              v-model="scheduleActionPayload.pondIds"
              @change="handleChangePond"
              :showSelectAll="true"
              :placeholder="$tc('Comn_pond_name', 0)"
              collapse-tags
              :loading="loading"
              value-key="_id"
              :reserve-keyword="true"
              multiple
              filterable
              size="small"
            >
              <template v-for="pond in getPondsBySelectedStatus">
                <el-option
                  :key="pond._id"
                  :label="pond.title"
                  :value="pond._id"
                ></el-option>
              </template>
            </er-select>
          </el-form-item>
        </ValidationProvider>
        <ValidationProvider
          rules="required"
          :name="$t('Comn_pondmother_code')"
          v-slot="{ errors }"
        >
          <el-form-item
            :label="$t('Comn_pondmother_code')"
            prop="pond_mothers"
            :error="errors[0]"
          >
            <er-select
              v-model="scheduleActionPayload.pondMothers"
              :placeholder="$t('Comn_pondmother_code')"
              :no-data-text="$t('Comn_no_data_connection')"
              :showSelectAll="true"
              :loading="loading"
              value-key="code"
              collapse-tags
              :reserve-keyword="true"
              multiple
              filterable
              :disabled="scheduleActionPayload.pondIds.length === 0"
              size="small"
            >
              <template v-for="pm in getSelectedPondsPms">
                <el-option
                  :key="pm._id"
                  :value="pm"
                  :label="upm__getValue(pm)"
                ></el-option>
              </template>
            </er-select>
          </el-form-item>
        </ValidationProvider>
      </el-form>
    </ValidationObserver>
    <layout-toolbar slot="footer" justify="center">
      <er-button
        size="mini"
        btnType="save"
        :showLabel="true"
        :showIcon="true"
        @click="saveChangedStatus"
        :disabled="isReadOnly"
        :loading="loading"
      ></er-button>
      <er-button
        size="mini"
        btnType="cancel"
        :showLabel="true"
        @click="handleCloseDialog('cancel')"
        :disabled="isReadOnly"
      ></er-button>
    </layout-toolbar>
  </er-dialog>
</template>

<script>
import filtersMixin from "@/mixins/filtersMixin";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
import { mapActions, mapGetters } from "vuex";
import { required } from "vee-validate/dist/rules";
import { extend } from "vee-validate";

export default {
  props: {
    action: {
      type: Object,
      required: true,
      default: () => ({})
    },
    dialogVisible: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  mixins: [filtersMixin, errorHandlerMixin, userPreferenceMixin],
  data: function () {
    return {
      loading: false,
      scheduleActionPayload: {
        pondIds: [],
        pondMothers: []
      }
    };
  },
  computed: {
    ...mapGetters("pondMotherSchedules", {
      getPondDetailsToPondId: "getPondDetailsToPondId",
      getPondDetailsToPondIdHavingPms: "getPondDetailsToPondIdHavingPms"
    }),
    ...mapGetters("pondMotherSchedules/scheduleActions", {
      getPondsBySelectedStatus: "getPondsBySelectedStatus",
      getPmsBySelectedStatus: "getPmsBySelectedStatus",
      getPondToPmsBySelectedStatus: "getPondToPmsBySelectedStatus"
    }),
    getPondIds() {
      return (Object.values(this.getPondDetailsToPondIdHavingPms) || []).map(
        (x) => x._id
      );
    },
    getSelectedPondsPms() {
      return this.scheduleActionPayload.pondIds
        .map((pondId) => this.getPondToPmsBySelectedStatus[pondId])
        .flat(1);
    },
    actionToDialogTitle() {
      return {
        RUNNING: () => this.$t("schedules.actions_dialog.play_title"),
        PAUSED: () => this.$t("schedules.actions_dialog.pause_title"),
        STOPPED: () => this.$t("schedules.actions_dialog.stop_title")
      };
    }
  },
  methods: {
    ...mapActions("pondMotherSchedules/scheduleActions", {
      fetchAllPMsInAllPondsByScheduleStatus:
        "fetchAllPMsInAllPondsByScheduleStatus",
      changeStatusOfArrPmTS: "changeStatusOfArrPmTS"
    }),
    handleOpenDialog() {
      this.loading = true;
      this.scheduleActionPayload = {
        pondIds: [],
        pondMothers: []
      };
      this.initValidations();
      this.upm__setDataKeys("code", "title");
      this.$nextTick(() => {
        console.log(this.$refs["pond-schedule-action-dialog-form"]);
        this.$refs["pond-schedule-action-dialog-form"].reset();
      });
    },
    initValidations() {
      extend("required", {
        ...required,
        message: this.$t("Comn_field_is_required", {
          field: "{_field_}"
        })
      });
    },
    async handleOpenedDialog() {
      try {
        await this.fetchAllPMsInAllPondsByScheduleStatus({
          pondIds: this.getPondIds,
          status: this.action.appliesOn
        });
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    handleChangePond() {
      this.scheduleActionPayload.pondMothers = this.getSelectedPondsPms;
    },
    async saveChangedStatus() {
      this.loading = true;
      const result = await this.$refs[
        "pond-schedule-action-dialog-form"
      ].validate();
      if (!result) {
        this.loading = false;
        return;
      }
      try {
        const arrPmIds = this.scheduleActionPayload.pondMothers.map((x) => {
          return {
            schedule_id: x.schedule_id,
            time_slot_id: x.time_slot_id,
            pond_mother_id: x._id
          };
        });
        await this.changeStatusOfArrPmTS({
          arrPondIds: this.scheduleActionPayload.pondIds,
          arrPmIds,
          status: this.action.key
        });
        this.handleCloseDialog("save-button-success");
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    handleCloseDialog(triggerName) {
      this.$emit("close-dialog", triggerName);
    }
  }
};
</script>

<style lang="scss" scoped>
.pond-schedule-action-dialog-form {
  &::v-deep .el-form-item--small.el-form-item {
    justify-content: flex-end;
  }
}
.pond-schedule-action-dialog {
  border-radius: 10px;
}
</style>
