<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: pondsMap.vue
Description:This file contains the combination of components like drawPondComponent,
editPondComponent,
activatePond,infoWindow,
mapControlsComponent,searchPlace,mapPondDetailsSideMenu.Functionality  wise adding, editing,drawing,activating the pond and pond related will shown in the side menu and gets the basic info on hover
-->
<template>
  <div class="map-container">
    <div id="map" v-loading="loading" element-loading-background="white"></div>
    <div class="map-controls-container">
      <edit-pond-component
        :map="map"
        v-if="getMapMode === 'EDITING'"
        :key="getCurrUserLocation._id + ' edit'"
        @refetch_data="initMaps"
      ></edit-pond-component>
      <activatePond
        :map="map"
        v-if="getMapMode === 'ACTIVATING'"
        :key="getCurrUserLocation._id + ' activate'"
        @refetch_data="initMaps"
      >
      </activatePond>
      <template v-if="showDefaultView">
        <draw-pond-component
          :map="map"
          :key="getCurrUserLocation._id + ' draw'"
          v-if="getMapMode !== 'EDITING'"
          @refetch_data="initMaps"
        ></draw-pond-component>
        <search-place
          :map="map"
          v-if="!hideSearchBar"
          :key="getCurrUserLocation._id + ' searchplace'"
        ></search-place>
        <map-controls-component
          :map="map"
          :key="getCurrUserLocation._id + ' controls'"
        ></map-controls-component>
      </template>
      <map-pond-details-side-menu
        :visible="sideMenuVisibleStatus"
        @close="handleClose"
      ></map-pond-details-side-menu>
      <info-window v-show="false"></info-window>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import mapUtils from "@/utils/mapUtils.js";
import Pond from "@/model/pond";
import Maps from "@/model/Maps";
import drawPondComponent from "@/components/map/drawPond";
import editPondComponent from "@/components/map/editPond";
import activatePond from "@/components/map/activatePond";
import infoWindow from "@/components/map/infoWindow";
import mapControlsComponent from "@/components/map/mapControls";
import searchPlace from "@/components/map/searchPlace";
import mapPondDetailsSideMenu from "@/components/map/mapPondDetailsSideMenu";
import errorHandlerMixin from "@/mixins/errorHandlerMixin.js";
import app from "@/config/app";
import { permissionsToStringMap as permissions } from "@/middleware/pageAccessManager";
import { handleStoreRegistration } from "@/utils/storeRegisterHandlerUtils";
import { Loader } from "@googlemaps/js-api-loader";
const GOOGLE_MAPS_API = app.GOOGLE_MAPS_API_KEY;

export default {
  mixins: [errorHandlerMixin],
  components: {
    drawPondComponent,
    editPondComponent,
    activatePond,
    mapControlsComponent,
    searchPlace,
    mapPondDetailsSideMenu,
    infoWindow
  },
  data: function () {
    return {
      mapData: null,
      map: null,
      drawingMode: false,
      activateSave: false,
      errorMessagesObject: new Pond(),
      loading: false,
      componentPermissions: {
        keyToValidateAccess: "PERMISSIONS",
        PERMISSIONS: [permissions.MANAGE_CULTURE]
      }
    };
  },
  watch: {
    getCurrLang: function (newLang, oldLang) {
      this.$router.go();
    },
    getSizeAlert: function (newSizeAlertStatus, oldSizeAlertStatus) {
      if (newSizeAlertStatus) {
        alert(this.$t("area_size_alert"));
        this.$store.commit("googleMaps/SET_POND_SIZE_ALERT", false);
      }
    },
    getIsAccessDenied: async function (newStatus, oldStatus) {
      if (newStatus) {
        await this.ehm__errorAccessDenied([
          { message: this.$gblUAMGetAccessDeniedMsg }
        ]);
        this.$store.commit("googleMaps/SET_ACCESS_DENIED", false);
      }
    }
  },
  async beforeRouteEnter(to, from, next) {
    // added google maps script tag at the time of entering into the component
    try {
      const language = localStorage.getItem("lang");
      // const language = this.$i18n.locale;
      const loader = new Loader({
        apiKey: GOOGLE_MAPS_API,
        version: "weekly",
        libraries: ["places", "drawing", "geometry"],
        language
      });
      await loader.load();
    } catch (err) {
      console.log(JSON.stringify(err));
    } finally {
      handleStoreRegistration(to, from);
      next();
    }
  },
  mounted() {
    const main_scroll_view = document.querySelector(".main-scroll-view");
    if (main_scroll_view) {
      document.querySelector(".main-scroll-view").style.height = "100%";
    }
    this.initMaps();
  },
  beforeDestroy() {
    const main_scroll_view = document.querySelector(".main-scroll-view");
    if (main_scroll_view) {
      document.querySelector(".main-scroll-view").style.height = "";
    }
  },
  computed: {
    ...mapGetters("googleMaps", {
      Ponds: "getPonds",
      getMapMode: "getMapMode",
      getArrPondDataWithHealth: "getArrPondDataWithHealth",
      getSizeAlert: "getSizeAlert",
      getClickedPond: "getCurrentClickedPond",
      getIsAccessDenied: "getIsAccessDenied",
      getPondIdToLatestNDOTempValues: "getPondIdToLatestNDOTempValues"
    }),
    ...mapGetters("user", {
      getStoreCurrUserLocation: "getCurrUserLocation"
    }),
    getCurrLang() {
      // return this.$i18n.locale;
      return localStorage.getItem("lang");
    },
    getCurrUserLocation() {
      return this.getStoreCurrUserLocation || {};
    },
    getCurrUserLocationId() {
      return this.getCurrUserLocation._id;
    },
    getClickedPondStatus() {
      return this.getClickedPond.status;
    },
    sideMenuVisibleStatus: {
      get() {
        if (this.getMapMode === "SHOW_DETAILS_OF_POND") {
          this.mixPanelEventGenerator({ eventName: "Map - Click On Pond" });
          return true;
        }
        return false;
      },
      set(value) {
        if (!value) {
          this.setMapMode("INITIAL");
        }
      }
    },
    showDefaultView() {
      return [
        "INITIAL",
        "DRAWING",
        "DRAW_POND_COMPLETE",
        "DRAW_POND_INSTR_1"
      ].includes(this.getMapMode);
    },
    hideSearchBar() {
      return ["DRAWING", "DRAW_POND_COMPLETE"].includes(this.getMapMode);
    },
    PondFeatureList() {
      const pond_feature_list = [];
      this.getArrPondDataWithHealth.forEach((pond) => {
        const properties = pond;
        properties.disolved_oxygen = {
          do: this.getPondIdToLatestNDOTempValues[pond._id]
            ? this.getPondIdToLatestNDOTempValues[pond._id][0].do
            : undefined,
          doConfig: this.getPondIdToLatestNDOTempValues[pond._id]
            ? this.getPondIdToLatestNDOTempValues[pond._id][0].doConfig
            : undefined
        };
        try {
          const featureObject = mapUtils.getFeaturePolygon(
            pond.geometry,
            pond._id,
            properties
          );
          pond_feature_list.push(featureObject);
        } catch (err) {
          console.error(err);
        }
      });
      return pond_feature_list;
    }
  },
  methods: {
    ...mapActions("googleMaps", {
      fetchAllPonds: "fetchAllPondDetails",
      fetchPondHealthData: "fetchPondHealthData",
      setMapMode: "setMapMode",
      fetchLatestDoAndTemp: "fetchLatestDoAndTemp"
    }),
    ...mapMutations("googleMaps", {
      INIT_PERMISSIONS: "INIT_PERMISSIONS"
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    initMaps() {
      this.loading = true;
      this.map = new Maps(document.getElementById("map"));
      this.mapData = this.map.mapData;
      console.log(this.mapData);
      this.map.setOptions("MAP");
      this.setMapMode("INITIAL");
      this.INIT_PERMISSIONS(this.componentPermissions);
      this.map.setMapListener();
      // waiting the child components to load when changes are made from watch
      this.$nextTick(async () => {
        this.initMapDOMElements();
        this.initMapControls();
        this.map.addMapDataListeners();
        await this.LoadPondsToMap();
        this.loading = false;
      });
    },
    initMapDOMElements() {
      var infoDiv = document.createElement("div");
      var childDiv1 = document.createElement("div");
      childDiv1.id = "pond-details-name";
      infoDiv.appendChild(childDiv1);
      this.map.domElements.nameOverlay = infoDiv;
      this.map.domElements.infoWindow =
        document.getElementById("infowindow-content");
      this.map.domElements.infoPondHealth = document.getElementById(
        "info-pond-health-hint"
      );
    },
    initMapControls() {
      // this.map.setControls("TOP_LEFT", this.map.domElements.locationControl);
      this.map.setControls("BOTTOM_LEFT", this.map.domElements.infoPondHealth);
    },
    async LoadPondsToMap() {
      // TODO: need to implement displaying based on zoom level displaying features
      let initPondCenter;
      try {
        if (this.getCurrUserLocation._id !== "") {
          await Promise.all([
            this.fetchAllPonds({
              location_id: this.getCurrUserLocation._id,
              status: ["ACTIVE", "INACTIVE"],
              // include: ["title", "status", "cultivation_date"],
              get_all: true
            }),
            this.fetchLatestDoAndTemp({
              location_id: this.getCurrUserLocation._id,
              exclude_previous_harvest_data: true
            })
          ]);
        }
        // .then(({ message, totalPonds }) => {
        if (this.Ponds.length > 0) {
          // this.mapData.add(pondFeatureList)
          const pond_feature_list = this.PondFeatureList;
          pond_feature_list.forEach((feature) => {
            this.mapData.add(feature);
          });
          const latLng = pond_feature_list[0]
            .getProperty("latLgnBounds")
            .getCenter();
          this.map.panTo(latLng);
          this.map.setMapCenter(latLng);
          this.map.setMapData();
        } else {
          mapUtils
            .captureUserLocation()
            .then((response1) => {
              initPondCenter = response1.location;
              this.map.addMarkerToMap(initPondCenter).setMap(this.map.map);
              this.map.panTo(initPondCenter);
            })
            .catch((err) => {
              console.error(err);
              initPondCenter = new google.maps.LatLng(0.777259, 91.142578);
            });
          this.map.addMarkerToMap(initPondCenter);
        }
      } catch (error) {
        this.ehm__errorMessages(error, true);
      }
    },
    handleClose() {
      this.sideMenuVisibleStatus = false;
    }
  }
};
</script>
<style lang="scss">
.map-container {
  position: relative;
  top: 0px;
  bottom: 0px;
  height: 100%;
  .el-drawer__wrapper {
    width: 40%;
    left: unset;
    top: var(--header-height);
  }
}
#map {
  top: 0px;
  bottom: 0px;
  position: relative;
  right: 0px;
  box-sizing: border-box;
  height: 100%;
}
.info-label {
  position: absolute;
  color: blanchedalmond;
  width: 25px;
  line-break: loose;
}
#overlayview-location {
  position: absolute;
  color: #e4f1fe;
  width: 60px;
  font-size: 1.3vw;
  font-weight: 800;
  line-break: loose;
  text-transform: capitalize;
}
.magnifier {
  position: absolute;
  color: blanchedalmond;
  width: 100px;
  line-break: loose;
  height: 100px;
  border: 1px solid white;
  border-radius: 50%;
}
.action-panel {
  margin: 10px;
}
.btn-draw {
  margin: 10px !important;
}
/* infowindow style */

div[id^="pond-details-name"] {
  color: #ffffff;
  background: #0073b5;
  // border: 1px solid #ffff00;
  width: max-content;
  border-radius: 12px;
  text-align: center;
  min-width: 50px;
  padding: 6px;
  margin: -20px;
  // white-space: nowrap;
  white-space: initial;
  overflow: hidden;
}

div[id^="pond-details-name"].inactive-pond {
  background: #dcdcdc;
  // border: 1px solid #ffff00;
}

.marquee {
  width: max-content;
  margin: 0 auto;
  // white-space: nowrap;
  white-space: initial;
  overflow: hidden;
  box-sizing: border-box;
}

.marquee span {
  display: flex;
  white-space: pre;
  padding-left: 60%;
  /* show the marquee just outside the paragraph */
  animation: marquee 10s linear infinite;
}

.active-tag {
  background: #33a02c;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 5px 10px;
  .circle {
    height: 6px;
    width: 6px;
    background-color: white;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
  }
}
// .marquee span:hover {
//   // animation-play-state: paused;
// }

/* Make it move */

@keyframes marquee {
  0% {
    transform: translate(-50%, 0);
  }
  50% {
    transform: translate(-70%, 0);
  }
  100% {
    transform: translate(-50%, 0);
  }
}
/// loading style

// .line {
//   display: inline-block;
//   width: 5px;
//   height: 5px;
//   border-radius: 5px;
//   background-color: #ffffff;
// }
// .load-3 .line:nth-last-child(1) {
//   animation: loadingC 0.6s 0.1s linear infinite;
// }
// .load-3 .line:nth-last-child(2) {
//   animation: loadingC 0.6s 0.2s linear infinite;
// }
// .load-3 .line:nth-last-child(3) {
//   animation: loadingC 0.6s 0.3s linear infinite;
// }
// @keyframes loadingC {
//   0% {
//     transform: translate(0, 0);
//   }
//   50% {
//     transform: translate(0, 5px);
//   }
//   100% {
//     transform: translate(0, 0);
//   }
// }
</style>
