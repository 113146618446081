<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: pmScheduleModeCollapseItem.vue
Description: This file contains UI components used to display collapsable pond of schdeule mode in PondMother schedules page - PondMother Level
-->
<template>
  <el-row>
    <el-row>
      <el-row type="flex" justify="center">
        <el-alert
          :title="$t('Comn_alerts')"
          type="error"
          @close="ehm__handleAlertClose"
          v-if="ehm__unhandledErrorMessage != ''"
          >{{ ehm__unhandledErrorMessage }}</el-alert
        >
      </el-row>
    </el-row>
    <el-row>
      <el-col class="add-btn-container hidden-lg-and-up" :span="24" :md="24">
        <el-row
          type="flex"
          justify="end"
          v-if="!hidePMComponentActionBtns(pmId)"
        >
          <er-button
            size="mini"
            btnType="add"
            icon="el-icon-circle-plus-outline"
            :disabled="disabledAddScheds()"
            :showLabel="true"
            @click="handleAddTSToPm"
          ></er-button>
          <er-button
            size="mini"
            type="danger"
            @click="handlePMClearFutureSchedules($event, pmId)"
            :disabled="isReadOnly"
            >{{ $t("PM_clr_future_sch") }}</er-button
          >
        </el-row>
      </el-col>
      <el-col :span="22">
        <el-form
          class="mode-collapse-item"
          v-loading="loading"
          element-loading-background="white"
        >
          <el-table
            :data="tableData"
            size="mini"
            :indent="10"
            class="card-row-table"
            header-row-class-name="card-row-table__header-row"
            :row-class-name="functionPmTSTableRowClassName"
          >
            <template slot="empty">
              <table-empty-state></table-empty-state>
            </template>
            <el-table-column
              v-if="mode === 'HYBRID'"
              :width="tableColumns.mode_g.width"
              :label="tableColumns.mode_g.label"
              header-align="center"
              align="left"
            >
              <!-- v-if="mode === 'HYBRID'" -->
              <template slot-scope="scope">
                <er-select
                  class="mode_dropdown_alignment"
                  :value="modeValue(scope.row.mode !== 'HYBRID' ? scope.row.mode : '')"
                  :placeholder="$t('select_mode')"
                  size="mini"
                  popper-class="mode-dropdown"
                  :disabled="disableMode(scope.row)"
                  @focus="handleFocusOnPmTS($event, scope.row, 'TIME_PICKER')"
                  @change="handleChangeInput($event, scope.row, 'mode_g')"
                >
                  <el-option
                    v-for="value in pondMotherModesOfOperations"
                    :label="$t(value.lang_key)"
                    :key="value.mode"
                    :value="value.mode"
                  >
                  <svg
                  v-if="value.mode !== 'HYBRID'"
                  class="list-item__color-swatch_pm"
                  viewBox="0 0 8 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="4" cy="4" r="3" :fill="modeColor(value.mode)">
                  </circle>
                </svg><span class="option_span">{{ $t(value.lang_key) }}</span>
                  </el-option>
                  <template slot="prefix">
                    <svg
                      class="list-item__color-swatch_pm"
                      viewBox="0 0 8 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="4" cy="4" r="3" :fill="modeColor(modeValue(scope.row.mode))">
                      </circle>
                    </svg>
                  </template>
                </er-select>
              </template>
            </el-table-column>
            <!-- <el-table-column type="index" width="50"></el-table-column> -->
            <el-table-column
              :width="tableColumns.s_time.width"
              :label="tableColumns.s_time.label"
              header-align="center"
              align="center"
            >
              <template slot-scope="scope">
                <el-time-picker
                  v-model="scope.row.s_time"
                  prefix-icon=" "
                  size="mini"
                  value-format="HH:mm"
                  format="HH:mm"
                  :clearable="false"
                  :picker-options="fromTimePickerOptions"
                  :disabled="disableFromTime(scope.row)"
                  @focus="handleFocusOnPmTS($event, scope.row, 'TIME_PICKER')"
                  @input="handleChangeTPicker($event, scope.row, 'from_time')"
                ></el-time-picker>
              </template>
            </el-table-column>
            <el-table-column
              :width="tableColumns.e_time.width"
              :label="tableColumns.e_time.label"
              header-align="center"
              align="center"
            >
              <template slot-scope="scope">
                <el-time-picker
                  :value="scope.row.e_time"
                  prefix-icon=" "
                  size="mini"
                  value-format="HH:mm"
                  format="HH:mm"
                  :clearable="false"
                  :picker-options="toTimePickerOptions"
                  :disabled="disableToTime(scope.row)"
                  @focus="handleFocusOnPmTS($event, scope.row, 'TIME_PICKER')"
                  @input="handleChangeTPicker($event, scope.row, 'to_time')"
                ></el-time-picker>
              </template>
            </el-table-column>
            <el-table-column
              :width="tableColumns.total_feed_kgs.width"
              :label="tableColumns.total_feed_kgs.label"
              header-align="center"
              align="center"
            >
              <template slot-scope="scope">
                <el-input
                  type="number"
                  size="mini"
                  v-model="scope.row.feed"
                  :min="0"
                  title
                  :precision="2"
                  :autofocus="false"
                  :disabled="disableTotalFeed(scope.row)"
                  :controls="false"
                  @focus="handleFocusOnPmTS($event, scope.row, 'NUMBER')"
                  @change="
                    handleChangeInput($event, scope.row, 'total_feed_kgs')
                  "
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              :width="tableColumns.ocf_g.width"
              :label="tableColumns.ocf_g.label"
              header-align="center"
              align="center"
            >
              <template slot-scope="scope">
                <el-input
                  type="number"
                  size="mini"
                  v-model="scope.row.ocf"
                  :min="200"
                  :precision="0"
                  title
                  :step="50"
                  :max="2500"
                  @focus="handleFocusOnPmTS($event, scope.row, 'NUMBER')"
                  :disabled="disableOCF(scope.row)"
                  :controls="false"
                  @change="handleChangeInput($event, scope.row, 'ocf_g')"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              :width="tableColumns.feed_gap.width"
              :label="tableColumns.feed_gap.label"
              header-align="center"
              align="center"
            ><!-- v-if="upm__showFeedGapField" -->
              <template slot-scope="scope">
                <el-input
                  type="number"
                  size="mini"
                  title
                  v-model="scope.row.feed_gap"
                  :precision="2"
                  :min="0"
                  :controls="false"
                  :disabled="disableFeedGap(scope.row)"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
            v-if="mode === 'HYBRID'"
              :width="tableColumns.feeding_level_g.width"
              :label="tableColumns.feeding_level_g.label"
              header-align="center"
              align="left"
            >
              <!-- v-if="mode === 'HYBRID'" -->
              <template slot-scope="scope">
                <er-select
                  class="mode_dropdown_alignment"
                  :value="feedingValue(scope.row)"
                  :placeholder="$t('Comn_feed_level')"
                  size="mini"
                  value-key="value"
                  popper-class="mode-dropdown"
                  :disabled="disableFeeding(scope.row)"
                  @change="handleChangeInput($event, scope.row, 'feeding_level_g')"
                >
                  <el-option
                    v-for="item in feedingLevelData"
                    :label="$t(item.label)"
                    :key="item.value"
                    :value="item.value"
                  ></el-option>
                </er-select>
              </template>
            </el-table-column>
            <el-table-column
              :width="tableColumns.dispensed_feed.width"
              :label="tableColumns.dispensed_feed.label"
              header-align="center"
              align="center"
            >
              <template slot-scope="scope">
                <el-input
                  type="number"
                  size="mini"
                  title
                  v-model="scope.row.dispensed_feed"
                  :precision="2"
                  :min="0"
                  :controls="false"
                  :disabled="true"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              :width="tableColumns.actions.width"
              header-align="center"
              align="center"
            >
              <template slot-scope="scope">
                <!-- schedule controls -->
                <el-row type="flex" align="middle">
                  <template v-if="!hideStatusActionBtns(scope.row) ||
                      !validScheduleCheckData[scope.row.bk_id]">
                    <er-button
                      v-if="scope.row.ui_status === PM_TS_STATUS.RUNNING"
                      class="btn-actions"
                      btnType="pause"
                      :showIcon="true"
                      size="mini"
                      :disabled="isReadOnly"
                      @click="
                        handleStatusChange(
                          $event,
                          scope.$index,
                          scope.row,
                          PM_TS_STATUS.PAUSED
                        )
                      "
                    ></er-button>
                    <er-button
                      v-if="scope.row.ui_status === PM_TS_STATUS.PAUSED"
                      class="btn-actions"
                      size="mini"
                      btnType="play"
                      :showIcon="true"
                      :disabled="isReadOnly"
                      @click="
                        handleStatusChange(
                          $event,
                          scope.$index,
                          scope.row,
                          PM_TS_STATUS.RUNNING
                        )
                      "
                    ></er-button>
                    <er-button
                      v-if="
                        [PM_TS_STATUS.RUNNING, PM_TS_STATUS.PAUSED].indexOf(
                          scope.row.ui_status
                        ) > -1
                      "
                      size="mini"
                      btnType="stop"
                      :showIcon="true"
                      class="btn-actions"
                      :disabled="isReadOnly"
                      @click="
                        handleStatusChange(
                          $event,
                          scope.$index,
                          scope.row,
                          PM_TS_STATUS.STOPPED
                        )
                      "
                    ></er-button>
                    <el-divider
                      direction="vertical"
                      v-if="
                        [PM_TS_STATUS.RUNNING, PM_TS_STATUS.PAUSED].indexOf(
                          scope.row.ui_status
                        ) > -1
                      "
                    ></el-divider>
                  </template>
                  <!-- schedule creation btns -->
                  <er-button
                    class="btn-actions"
                    v-show="!isPmTSCompleted(scope.row)"
                    size="mini"
                    icon="el-icon-minus"
                    :disabled="disableDelTS(scope.row)"
                    @click="handleDeleteTSFromPm(scope.$index, scope.row)"
                  ></er-button>
                </el-row>
              </template>
            </el-table-column>
          </el-table>
          <el-row
            type="flex"
            class="save-btn-container"
            v-if="!hidePMComponentActionBtns(pmId)"
          >
            <er-button
              size="mini"
              btnType="save"
              :showLabel="true"
              :showIcon="true"
              :disabled="isReadOnly"
              @click="handleSaveScheduleClick"
            ></er-button>
          </el-row>
        </el-form>
      </el-col>
      <el-col
        class="add-btn-container hidden-md-and-down"
        :span="2"
      >
        <el-row
          type="flex"
          justify="space-between"
          v-if="!hidePMComponentActionBtns(pmId)"
        >
          <er-button
            size="mini"
            btnType="add"
            icon="el-icon-circle-plus-outline"
            :disabled="disabledAddScheds()"
            :showLabel="false"
            @click="handleAddTSToPm"
          ></er-button>
          <er-button
            :title="$t('PM_clr_future_sch')"
            size="mini"
            type="danger"
            :showLabel="false"
            icon="el-icon-remove-outline"
            @click="handlePMClearFutureSchedules($event, pmId)"
            :disabled="isReadOnly"></er-button
          >
        </el-row>
      </el-col>
    </el-row>
  </el-row>
</template>

<script>
import pmSchedulesMixin from "@/mixins/pmSchedulesMixin";
import { mapGetters, mapActions, mapMutations } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import { PM_TS_STATUS, PM_MODES } from "@/constants/schedule";
import PondMother from "@/model/pondmother";
import dateUtils from "@/utils/dateUtils";

export default {
  mixins: [errorHandlerMixin, pmSchedulesMixin],
  props: ["pm", "pondId", "mode"],
  data: function() {
    return {
      pastPickedTime: [],
      fromTimePickerOptions: {
        format: "HH:mm"
      },
      toTimePickerOptions: {
        format: "HH:mm"
      },
      loading: false,
      deletedTSBkIds: [],
      errors: [],
      PM_TS_STATUS,
      feedingLevel: [
        {
          label: "Comn_normal",
          value: 0
        },
        {
          label: "Comn_economical",
          value: 1
        },
        {
          label: "Comn_aggressive",
          value: 2
        },
      ],
      feedingLevelSuper: [
        {
          label: "Comn_normal",
          value: 0
        },
        {
          label: "Comn_economical",
          value: 1
        },
        {
          label: "Comn_aggressive",
          value: 2
        },
        {
          label: "Comn_super_aggressive",
          value: 3
        }
      ],
      cmCurrTimeSecs: dateUtils.getCurrTimeSecsInGivenTZ,
      validScheduleCheckData: {}
    };
  },
  computed: {
    ...mapGetters("pondMotherSchedules", {
      getPmTSTableData: "getPmTSTableData",
      getDateRange: "getDateRange",
      getPondTSTableData: "getPondTSTableData",
      getSelectedDay: "getSelectedDay",
      getSelectedPondStIdsToPmIds: "getSelectedPondStIdsToPmIds",
      isCurrentDay: "isCurrentDay",
    }),
    ...mapGetters('user', {
      getUserId: "getUserId"
    }),
    feedingLevelData() {
      if (this.getUserId === "5f74984140f3e1412d1de9cd" ||
          this.getUserId === "5e5cfb322c599d13d25b607e" ||
          this.getUserId === "5f098445be50fddb616e47c9") {
        return this.feedingLevelSuper;
      } else {
        return this.feedingLevel;
      }
    },
    pmId: function() {
      return this.pm._id;
    },
    modeValue() {
      return (mode) => {
        return mode === 'FARMER' ? 'SCHEDULE' : mode === 'SHRIMP_TALK' ? 'AUTOMATIC' : mode === 'MANUAL' ? 'BASIC' : mode;
      }
    },
    modeColor() {
      return (mode) => {
        if (mode !== 'HYBRID') {
          return PondMother.generateColorForMode(mode).color;
        }
        return null;
      }
    },
    pondMotherModesOfOperations() {
      return {
        SHRIMP_TALK: { mode: 'AUTOMATIC', lang_key: 'PM_automatic' },
        FARMER: { mode: "SCHEDULE", lang_key: "Comn_schedule" },
        // MANUAL: { mode: "BASIC", lang_key: "PM_sch_mode4" }
      };
    },
    selectedDay: function() {
      return this.getSelectedDay;
    },
    isExpanded() {
      return (
        (this.getExpandedPmKeysPondId[this.pondId] || []).indexOf(this.pmId) >
        -1
      );
    },
    tableDataObj: function() {
      const key = `${this.selectedDay}_${this.pondId}_${this.pmId}`;
      return this.getPmTSTableData[key];
    },
    tableData: function() {
      // console.log("this.$lodash.cloneDeep(Object.values(this.tableDataObj || {}))", this.mode, this.$lodash.cloneDeep(Object.values(this.tableDataObj || {})).filter(val => val.running_mode === 'HYBRID'));
      // const tableData = (this.mode === "SCHEDULE" || this.mode === "FARMER") ? this.$lodash.cloneDeep(Object.values(this.tableDataObj || {})).filter(val => val.running_mode === undefined || val.running_mode !== 'HYBRID') : this.$lodash.cloneDeep(Object.values(this.tableDataObj || {})).filter(val => val.running_mode === 'HYBRID');
      // console.log("tableData---", tableData);
      // return tableData;
      return this.$lodash.cloneDeep(Object.values(this.tableDataObj || {}));
    },
    dateRange: function() {
      return this.getDateRange;
    },
    pm_id: function() {
      return this.pm._id;
    },
    getSTId() {
      return this.getSelectedPondStIdsToPmIds[this.pmId];
    },
    dateRange0: function() {
      return this.dateRange[0];
    },
    dateRange1: function() {
      return this.dateRange[1];
    },
    pondSchedule() {
      return this.getPondTSTableData[this.pondScheduleKey];
    },
    pondScheduleKey() {
      return this.selectedDay + "_" + this.pondId;
    },
    pmMode() {
      return this.pm.managed_by;
    },
    sortedTableData: function() {
      return this.$lodash
        .cloneDeep(this.tableData)
        .sort((a, b) => a.s_time_secs - b.e_time_secs);
    }
  },
  mounted() {
    this.validScheduleCheck();
  },
  methods: {
    ...mapActions("pondMotherSchedules", {
      addPmTSToPmTSTableAndPondTSTable: "addPmTSToPmTSTableAndPondTSTable",
      addTSToPondTSTable: "addTSToPondTSTable",
      deletePmTSFromPondTS: "deletePmTSFromPondTS",
      updatePmTSTime: "updatePmTSTime",
      createSchedule: "createPondSchedule",
      updatePmTSFeedConfigParams: "updatePmTSFeedConfigParams",
      savePondMotherLevelSchedule: "savePondMotherLevelSchedules"
    }),
    ...mapMutations("pondMotherSchedules", {
      CHECK_VALID_PM_DATA_TO_SAVE: "CHECK_VALID_SCHEDULE_MODE_PM_DATA_TO_SAVE"
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    disabledAddScheds() {
      const tableLengthWithOutCmpltdTS = this.tableData
        .filter(x => ![PM_TS_STATUS.COMPLETED].includes(x.ui_status))
        .filter(x => !(x.ui_status === "STOPPED" && x.SAVED_AT_DEVICE)).length;
      if (tableLengthWithOutCmpltdTS >= 8) return true;
      if (this.isReadOnly) return true;
      return false;
    },
    commonDisableCasesForAllFields: function(ts, field_name) {
      const casesToDisable = [
        !ts.enabled[field_name],
        [
          PM_TS_STATUS.INVALID,
          PM_TS_STATUS.COMPLETED,
          PM_TS_STATUS.STOPPED,
          PM_TS_STATUS.UI_COMPLETED
        ].indexOf(ts.ui_status) > -1,
        this.validScheduleCheckData[ts.bk_id] ? this.validScheduleCheckData[ts.bk_id] : false
        // this.cmCurrTimeSecs(this.getUserTimeZoneString) > ts.e_time_secs
      ];
      //  this.checkEndTimeLessThanCurrentTime(ts),
      return casesToDisable.includes(true);
    },
    validScheduleCheck: function() {
      this.$lodash.cloneDeep(Object.values(this.tableDataObj || {})).map(ts => {
        this.validScheduleCheckData[ts.bk_id] = this.isCurrentDay && this.cmCurrTimeSecs(this.getUserTimeZoneString) > ts.e_time_secs
      })
    },
    disableFromTime(pmTS) {
      const casesToDisable = [
        this.commonDisableCasesForAllFields(pmTS, "from_time"),
        pmTS.ui_status === PM_TS_STATUS.RUNNING,
        pmTS.ui_status === PM_TS_STATUS.UI_RUNNING
      ];
      //  this.checkSTimeLessThanCurrentTime(pmTS),
      if (casesToDisable.includes(true)) {
        return true;
      } else {
        return false;
      }
    },
    disableToTime(pmTS) {
      const casesToDisable = [
        this.commonDisableCasesForAllFields(pmTS, "to_time")
      ];
      //  this.checkSTimeLessThanCurrentTime(pmTS),
      if (casesToDisable.includes(true)) {
        return true;
      } else {
        return false;
      }
    },
    disableTotalFeed(pmTS) {
      const casesToDisable = [
        this.commonDisableCasesForAllFields(pmTS, "feed")
      ];
      if (casesToDisable.includes(true)) {
        return true;
      } else {
        return false;
      }
    },
    disableMode(pmTS) {
      const casesToDisable = [
        this.commonDisableCasesForAllFields(pmTS, "mode"),
        pmTS.ui_status === PM_TS_STATUS.RUNNING,
        pmTS.ui_status === PM_TS_STATUS.UI_RUNNING
      ];
      if (casesToDisable.includes(true)) {
        return true;
      } else {
        return false;
      }
    },
    disableFeeding(pmTS) {
      const casesToDisable = [
        this.commonDisableCasesForAllFields(pmTS, "feeding_level")
      ];
      if (casesToDisable.includes(true)) {
        return true;
      } else {
        if (pmTS.mode !== "SHRIMP_TALK" && pmTS.mode !== 'AUTOMATIC') {
          return true;
        } else {
          return false;
        }
      }
    },
    feedingValue(data) {
      if (data.feeding_level === -1) {
        return 0;
      } else {
        return data.feeding_level ? data.feeding_level : 0;
      }
    },
    disableFeedGap(pmTS) {
      const casesToDisable = [
        this.commonDisableCasesForAllFields(pmTS, "feed_gap")
      ];
      if (casesToDisable.includes(true)) {
        return true;
      } else {
        return false;
      }
    },
    disableOCF(pmTS) {
      const casesToDisable = [this.commonDisableCasesForAllFields(pmTS, "ocf")];
      if (casesToDisable.includes(true)) {
        return true;
      } else {
        if (!this.disableFeeding(pmTS)) {
          return true;
        } else {
          return false;
        }
      }
    },
    disableDelTS(pmTS) {
      const casesToDisable = [
        pmTS.ui_status === PM_TS_STATUS.COMPLETED,
        pmTS.ui_status === PM_TS_STATUS.RUNNING,
        pmTS.ui_status === PM_TS_STATUS.UI_RUNNING,
        !pmTS.enabled.act_btn_del,
        this.isReadOnly
      ];
      if (casesToDisable.includes(true)) {
        return true;
      } else {
        return false;
      }
    },
    handleAddTSToPm() {
      try {
        this.$gblUAMCanUserEdit(this.permissions);
        const presentPmTS = this.sortedTableData[this.tableData.length - 1];
        // adding the ts to the pm day timeslots
        if (!presentPmTS) {
          this.addPmTSToPmTSTableAndPondTSTable({
            selectedDay: +this.selectedDay,
            pmId: this.pmId,
            siblingTSId: undefined,
            pondId: this.pondId,
            mode: this.mode === "HYBRID" ? 'HYBRID' : PM_MODES.SCHEDULE
          });
        } else {
          this.addPmTSToPmTSTableAndPondTSTable({
            selectedDay: +this.selectedDay,
            pmId: this.pmId,
            siblingTSId: presentPmTS.ui_id,
            pondId: this.pondId,
            mode: this.mode === "HYBRID" ? 'HYBRID' : PM_MODES.SCHEDULE
          });
        }
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }
    },
    handleDeleteTSFromPm(index, ts) {
      this.handleDeleteTSFromPond(ts);
    },
    handleDeleteTSFromPond(ts) {
      // deleting the pm from that timeslot
      if (ts.bk_id) {
        this.deletedTSBkIds.push(ts.bk_id);
      }
      this.deletePmTSFromPondTS({
        selectedDay: this.selectedDay,
        pondId: this.pondId,
        pmId: this.pmId,
        pmTSId: ts.ui_id
      });
    },
    handleChangeTPicker(updtPropVal, changedTSObj, propertyName) {
      try {
        const currTSId = changedTSObj.ui_id;
        this.updatePmTSTime({
          prop: propertyName,
          value: updtPropVal,
          selectedDay: +this.selectedDay,
          currTSId,
          pondId: this.pondId,
          pmId: this.pmId,
          userTimeZoneString: this.getUserTimeZoneString
        });
      } catch (err) {
        console.error(err);
      }
    },
    handleChangeInput(updtPropVal, changedTSObj, propertyName) {
      const currTSId = changedTSObj.ui_id;
      this.updatePmTSFeedConfigParams({
        prop: propertyName,
        value: updtPropVal,
        selectedDay: +this.selectedDay,
        currTSId,
        pondId: this.pondId,
        pmId: this.pmId,
        userTimeZoneString: this.getUserTimeZoneString
      });
    },
    async handleStatusChange(event, indexInTable, changedTSObj, newStatus) {
      try {
        this.$gblUAMCanUserEdit(this.permissions);
        await this.TSStatusChangeConfirm(newStatus);
        this.loading = true;
        this.ehm__unhandledErrorMessage = "";
        await this.changeStatusOfPmTS({
          tsId: this.tableData[indexInTable].bk_id,
          pondId: this.pondId,
          pmId: this.pmId,
          status: newStatus,
          mode: "FARMER"
        });
        this.notifyScheduleSuccess("status");
        this.mixPanelEventGenerator({ eventName: "Feeding - PondMother - PondMother Collapse - " + newStatus + " Schedules" });
      } catch (err) {
        if (err !== "cancel") {
          this.ehm__errorMessages(err, true);
        }
      } finally {
        this.loading = false;
      }
    },
    handleCloseDialog() {
      this.showDialog = false;
      this.errors = [];
    },
    async handleSaveScheduleClick() {
      try {
        this.$gblUAMCanUserEdit(this.permissions);
        this.CHECK_VALID_PM_DATA_TO_SAVE({
          selectedDay: this.selectedDay,
          pondId: this.pondId,
          pmId: this.pmId,
          deletedTSBkIds: this.deletedTSBkIds,
          stId: this.getSTId
        });
        this.deletedTSBkIds = [];
        await this.checkWhetherToSaveSameSchedForRemainingDays();
        this.loading = true;
        await this.savePondMotherLevelSchedule({
          pmId: this.pmId,
          pondId: this.pondId
        });
        this.ehm__unhandledErrorMessage = "";
        this.notifyScheduleSuccess("saved");
        this.mixPanelEventGenerator({ eventName: "Feeding - PondMother - PondMother Collapse - Save Schedules" });
      } catch (err) {
        this.errorFailToSaveHandler(err);
      } finally {
        this.loading = false;
      }
    },
    ehm__error422Handler: function(err) {
      const errors = err.response.data.errors;
      let message =
        err.response.data.message || this.$t("Something_went_wrong_try_again");
      if (
        errors &&
        Array.isArray(errors.details) &&
        errors.details.length > 0
      ) {
        message = errors.details[0].message;
      }
      this.ehm__errorFailToSave([{ message }]);
    }
  }
};
</script>

<style lang="scss">
.list-item__color-swatch_pm {
  height: 10px !important;
  width: 10px !important;
}
.option_span {
  margin-left: 4px;
}
.mode_dropdown_alignment {
  .el-input__inner {
     padding-left: 0px !important;
     padding-right: 16px !important;
     display: flex;
     justify-content: center;
  }
  .el-input__prefix {
    display: flex;
    justify-content: center;
    margin-top: 8px;
  }
}
</style>
